import { HiddenInput } from "@ui/blocks/ToggleSwitch/elements/HiddenInput";
import { FC } from "react";
import { Slider } from "@ui/blocks/ToggleSwitch/elements/Slider";
import { Wrapper } from "@ui/blocks/ToggleSwitch/elements/Wrapper";
import { SliderIcon } from "@ui/blocks/ToggleSwitch/elements/SliderIcon";

interface ToggleSwitchProps {
  isChecked: boolean;
  onToggleChange: () => void;
  sliderIcon: string;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  isChecked,
  onToggleChange,
  sliderIcon,
}) => {
  return (
    <Wrapper>
      <HiddenInput
        type="checkbox"
        checked={isChecked}
        onChange={onToggleChange}
      />
      <Slider>
        <SliderIcon src={sliderIcon} alt="slider-icon" />
      </Slider>
    </Wrapper>
  );
};
