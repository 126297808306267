import { SesionStorageKeys } from "@/shared/constants/sesion-storage-keys";
import { create, defaulted, enums } from "superstruct";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScreenName, ScreenNameType } from "@features/tracking/screen-names";
import { RootState } from "@/shared/store";

const streamNameTypeObject = defaulted(
  enums(Object.values(ScreenName)),
  ScreenName.Unknown,
);

const getInitialState = () => {
  const prevStreamNameRaw =
    sessionStorage.getItem(SesionStorageKeys.PreviousScreen) ?? undefined;
  const currentStreamName =
    sessionStorage.getItem(SesionStorageKeys.CurrentScreen) ?? undefined;

  return {
    previous_screen: create(prevStreamNameRaw, streamNameTypeObject),
    current_screen: create(currentStreamName, streamNameTypeObject),
  };
};

const setCurrentAndPreviousScreenToStorage = (
  current: ScreenNameType,
  previous: ScreenNameType,
) => {
  try {
    sessionStorage.setItem(SesionStorageKeys.PreviousScreen, previous);
    sessionStorage.setItem(SesionStorageKeys.CurrentScreen, current);
  } catch (e) {
    // Fails in silence and sadness
  }
};

export const screenNameSlice = createSlice({
  name: "screenName",
  initialState: getInitialState(),
  reducers: {
    setCurrentScreen: (state, action: PayloadAction<ScreenNameType>) => {
      state.previous_screen = state.current_screen;
      state.current_screen = action.payload;

      setCurrentAndPreviousScreenToStorage(
        state.current_screen,
        state.previous_screen,
      );
    },
  },
});

export const { setCurrentScreen } = screenNameSlice.actions;
export const screenNameReducer = screenNameSlice.reducer;

export const selectCurrentScreen = (state: RootState): ScreenNameType =>
  state.screenName.current_screen;

export const selectPreviousScreen = (state: RootState): ScreenNameType => {
  return state.screenName.previous_screen;
};
