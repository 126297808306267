import React, { FC } from "react";
import { Wrapper } from "@ui/blocks/CompetitionsFilterItem/elements/Wrapper";
import { ItemLabel } from "@ui/blocks/CompetitionsFilterItem/elements/ItemLabel";
import { isNullish } from "@/shared/helpers/isNullish";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { Button, ButtonVariant } from "@ui/elements/Button";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";

export interface CompetitionsFilterItemProps {
  text: string;
  isSelected: boolean;
  onItemClick: (itemID: string) => void;
  numberOfAvailableMatches?: number;
  id?: string;
  icon?: ImageWithFallBackProps;
}

export const CompetitionsFilterItem: FC<CompetitionsFilterItemProps> = ({
  onItemClick,
  id = "",
  numberOfAvailableMatches,
  text,
  icon,
  isSelected,
}) => {
  return (
    <Wrapper>
      <input
        id={id}
        type="radio"
        value={id}
        checked={isSelected}
        onChange={(e) => onItemClick(e.target.value)}
      />
      <ItemLabel htmlFor={id}>
        <Button variant={ButtonVariant.action} onClick={() => onItemClick(id)}>
          <ImageWithFallBack
            height={24}
            width={24}
            src={icon?.src}
            alt={icon?.alt}
          />
          <BodyText
            color={ThemeColor.BodyText}
            style={{ marginLeft: "0.5rem" }}
          >
            {text}
            {!isNullish(numberOfAvailableMatches) &&
              ` (${numberOfAvailableMatches})`}
          </BodyText>
        </Button>
      </ItemLabel>
    </Wrapper>
  );
};
