import { WithThemeType } from "@themeTypes/WithThemeType";
import styled from "styled-components";

export const SocialMediaLink = styled.a<WithThemeType>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.35rem;
`;
