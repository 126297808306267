import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";

interface DocumentWithFullscreen extends Document {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
}

const SAFARI_FULLSCREEN_VIDEO_LAYERS_FIX_CLASSNAME =
  "safari-fullscreen-video-layers-fix";

export interface UseFullscreen {
  isFullScreen: boolean;
  wasFullScreen: boolean;
}

export const useFullScreen = (): MutableRefObject<UseFullscreen> => {
  const fullScreenRef = useRef<UseFullscreen>({
    isFullScreen: false,
    wasFullScreen: false,
  });

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const doc = document as DocumentWithFullscreen;
      const isFS =
        Boolean(doc.fullscreenElement) || Boolean(doc.webkitFullscreenElement);

      fullScreenRef.current.isFullScreen = isFS;

      if (isFS) {
        fullScreenRef.current.wasFullScreen = true;
      }

      /*
        Safari has a bug with elements with `transform: perspective rotateX` and
        they may appear on top of the fullscreen video. We have to toggle a
        CSS class on body so that it resets transform property for all children
        and nothing appears on top of a fullscreen video in Safari. On exit from
        the fullscreen mode we remove this CSS class. It may look ugly but
        unfortunately there is no way to workaround this issue in Safari without
        removal of `transform: perspective rotateX` and related
        `translate3d(0, 0, 0) translateZ(10em)` (see `elevated-layer` mixin). We
        cannot remove those transformation and achieve desired styling with
        other CSS rules.
      */
      document.body.classList.toggle(
        SAFARI_FULLSCREEN_VIDEO_LAYERS_FIX_CLASSNAME,
      );
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);
    document.addEventListener("webkitfullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);

      document.removeEventListener(
        "webkitfullscreenchange",
        onFullscreenChange,
      );

      document.body.classList.remove(
        SAFARI_FULLSCREEN_VIDEO_LAYERS_FIX_CLASSNAME,
      );
    };
  }, []);

  return fullScreenRef;
};
