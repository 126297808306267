import { DefaultThemeType } from "styled-components";
import { desktopFontSizes, mobileFontSizes } from "@/shared/themes/fontSizes";

const SystemColors = {
  hypeYellow: "#E1FF57",
};

const ColorPalette = {
  white: "#FFFFFF",
  gray0: "#F5F5F5",
  gray1: "#ECECEC",
  gray2: "#C4C2D0",
  gray3: "#A19DB3",
  gray4: "#6D6A7F",
  gray5: "#403E4A",
  gray6: "#2B2B2B",
  gray7: "#F3F4F5",
  black: "#000000",
  black0: "#1A1A1A",
};

export const defaultTheme: DefaultThemeType = {
  breakpoints: {
    mobile: "768px",
    tabletPortrait: "1024px",
    tabletLandscape: "1280px",
  },
  roundedCorners: {
    regular: "0.25rem",
    big: "0.5rem",
  },
  colors: {
    // COLORS
    yellowCard: "#FAFF03",
    redCard: "#EC0A33",
    pitchLineup: "#007846",
    brandPrimary: SystemColors.hypeYellow,
    brandSecondary: "#2A45F0",
    brandTernary: "#D81664",
    brandQuaternary: "#CC3E00",
    brandQuinary: "#9600FF",
    // GREYSCALE
    activeElement: ColorPalette.black0,
    background: ColorPalette.white,
    backgroundReverse: ColorPalette.black,
    bodyText: ColorPalette.gray5,
    border: ColorPalette.gray2,
    elevatedBackground: ColorPalette.gray0,
    headline: ColorPalette.black0,
    inactiveState: ColorPalette.gray3,
    labeltext: ColorPalette.gray4,
    navigationBackground: ColorPalette.gray7,
  },
  typography: {
    desktopFontSize: desktopFontSizes,
    mobileFontSize: mobileFontSizes,
  },
};

export const darkTheme: DefaultThemeType = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    redCard: "#FF2F54",
    pitchLineup: "#1DBB79",
    brandSecondary: "#4E95FF",
    brandTernary: "#FF5894",
    brandQuaternary: "#FF7800",
    brandQuinary: "#C46FFF",
    // GREYSCALE
    activeElement: SystemColors.hypeYellow,
    background: ColorPalette.black,
    backgroundReverse: ColorPalette.white,
    bodyText: ColorPalette.white,
    border: ColorPalette.gray6,
    elevatedBackground: ColorPalette.black0,
    headline: ColorPalette.gray7,
    inactiveState: "#B8B8B8",
    labeltext: ColorPalette.gray0,
    navigationBackground: ColorPalette.black0,
  },
};
