import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const SearchboxWrapper = styled.div<WithThemeType>`
  margin-bottom: 1rem;

  .ais-SearchBox-form,
  .ais-SearchBox-input,
  .ais-SearchBox-input::placeholder {
    background-color: ${(p) => p.theme.colors.background};
    color: ${(p) => p.theme.colors.bodyText};
    caret-color: ${(p) => p.theme.colors.bodyText};
  }
`;
