import { Button } from "@ui/elements/Button";
import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const ProfileButtonMobile = styled(Button)`
  display: grid;
  place-content: center;

  @media all and (${deviceSizeQuery.tabletPortrait}) {
    display: none;
  }
`;
