interface ImageLoaderProps {
  src: string;
  width?: number;
  height?: number;
}

export const IMAGIX_PREFIX = "https://image-service.onefootball.com/transform";

export const getImageServiceSrc = (props: ImageLoaderProps): string => {
  const { src, width, height } = props;

  // no need to update image url if it already received from image-service
  if (src.includes("image-service") || !src.includes("https")) {
    return src;
  }

  const img = encodeURI(src);

  return `${IMAGIX_PREFIX}?w=${width ?? ""}&h=${height ?? ""}&dpr=2&image=${img}`;
};
