import { boolean, string, type, Infer, optional } from "superstruct";

export const VideojsPlayerConfigStruct = type({
  autoplay: boolean(),
  aspectRatio: string(),
  liveui: optional(boolean()),
  controls: boolean(),
});

export type VideojsPlayerConfig = Infer<typeof VideojsPlayerConfigStruct>;
