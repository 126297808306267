import { FC } from "react";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { FontSize } from "@themeTypes/FontSizeType";
import { TitleText } from "@ui/elements/TitleText";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";

export const ErrorPage: FC = () => {
  useScreenName(ScreenName.Error);
  usePageTracking();

  return (
    <>
      <TitleText as="h1" color={ThemeColor.BodyText} size={FontSize.Title_3}>
        Oops!
      </TitleText>
      <BodyText color={ThemeColor.BodyText}>
        Sorry, an unexpected error has occurred.
      </BodyText>
    </>
  );
};
