// We need this helper because our api routes on web experience api are translated for some reason
// I hope that we will address this issue and have all routes in the same lang one day

export const langToApiRoute = (locale: string): string => {
  const routeTranslations: Record<string, string> = {
    en: `${locale}/match`,
    de: `${locale}/spiel`,
    it: `${locale}/partita`,
    es: `${locale}/partido`,
    "pt-br": `${locale}/match`,
    id: `${locale}/match`,
    fr: `${locale}/match`,
  };
  return routeTranslations[locale];
};
