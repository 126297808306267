import { FC } from "react";
import { FontSize } from "@themeTypes/FontSizeType";
import { Wrapper } from "@ui/blocks/AdBlockerBanner/elements/Wrapper";
import { BannerText } from "@ui/blocks/AdBlockerBanner/elements/BannerText";
import { isNullish } from "@/shared/helpers/isNullish";
import { PosterWrapper } from "@ui/blocks/AdBlockerBanner/elements/PosterWrapper";

interface AdBlockerBannerContentProps {
  text: string;
  posterUrl?: string;
}

export const AdBlockerBanner: FC<AdBlockerBannerContentProps> = ({
  text,
  posterUrl,
}) => {
  return (
    <Wrapper>
      <BannerText size={FontSize.DrukTitle_5} fontFamily="Druk">
        {text}
      </BannerText>
      <PosterWrapper>
        {!isNullish(posterUrl) && (
          <img src={posterUrl} alt="video-poster" width="100%" />
        )}
      </PosterWrapper>
    </Wrapper>
  );
};
