import { isProd } from "@/shared/helpers/isProd";

export interface EnvConfigType {
  RUDDERSTACK_KEY: string;
  RUDDERSTACK_DATA_PLANE: string;
}

export const configProd: EnvConfigType = {
  RUDDERSTACK_KEY: "2hVdDB4NBSB9Qv74ysqQhaFp5SO",
  RUDDERSTACK_DATA_PLANE: "https://onefootbalnurg.dataplane.rudderstack.com",
};

export const configDev: EnvConfigType = {
  RUDDERSTACK_KEY: "2hVdDB4NBSB9Qv74ysqQhaFp5SO",
  RUDDERSTACK_DATA_PLANE: "https://onefootbalnurg.dataplane.rudderstack.com",
};

export const envConfig = isProd ? configProd : configDev;
