import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const MatchInfoItem = styled.div`
  margin-right: 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media all and ${deviceSizeQuery.tabletPortrait} {
    margin-bottom: 0;
  }
`;
