import "instantsearch.css/themes/satellite.css";
import {
  Configure,
  InstantSearch,
  RefinementList,
  SearchBox,
} from "react-instantsearch";
import { VideosPageWrapper } from "@ui/elements/VideosPageWrapper";
import { FC, useMemo } from "react";
import { getSearchClient } from "@features/clips/services/getSearchClient";
import { VideoSearchHitsList } from "@ui/blocks/VideoSearchHitsList";
import { SearchboxWrapper } from "@features/clips/elements/SearchboxWrapper";
import { RafinementListWrapper } from "@features/clips/elements/RafinementListWrapper";
import { ScreenName, ScreenNameType } from "@features/tracking/screen-names";
import { useLocale } from "@/shared/hooks/useLocale";
import { useLocation } from "react-router-dom";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { useGetLocationQuery } from "@features/tracking/services/geolocationApi";
import { ListTitle } from "@features/liveMatches/elements/ListTitle";
import { FontSize } from "@themeTypes/FontSizeType";
import i18n from "i18next";
import { ClipsGrid } from "@features/clips/elements/ClipsGrid";
import { LogoLoadingSpinner } from "@ui/blocks/LoadingIcon/LoadinIcon";
import { LogoSpinnerCentered } from "@ui/blocks/LoadingIcon/LogoSpinnerCentered";

// Will only be used on clips and highlights pathname
// if the page is reused, add appropriate pathname and their matching ScreenNames
const screenFromPathname: Record<string, ScreenNameType> = {
  "/clips": ScreenName.Clips,
  "/highlights": ScreenName.Highlights,
};

export const ClipsPage: FC = () => {
  const searchClient = useMemo(() => getSearchClient(), []);
  const { data, isLoading, isSuccess } = useGetLocationQuery();
  const { locale } = useLocale();
  const { pathname } = useLocation();
  const { t } = i18n;

  const isHighlights = pathname === "/highlights";

  useScreenName(screenFromPathname[pathname]);
  usePageTracking();

  if (isLoading) {
    return (
      <LogoSpinnerCentered>
        <LogoLoadingSpinner />;
      </LogoSpinnerCentered>
    );
  }

  return (
    isSuccess && (
      <InstantSearch
        searchClient={searchClient}
        indexName="production.sorted.freshness"
      >
        <Configure
          hitsPerPage={40}
          filters={`languages:${locale} AND ${isHighlights ? "" : "NOT"} category:highlights AND contentType:horizontal_video AND (geoAvailability:${data} OR geoAvailability:world)`}
        />
        <VideosPageWrapper>
          <ListTitle size={FontSize.DrukTitle_4}>
            {isHighlights ? t("NAVIGATION_HIGHLIGHTS") : t("NAVIGATION_VIDEOS")}
          </ListTitle>
          <SearchboxWrapper>
            <SearchBox placeholder="Search video library..." />
          </SearchboxWrapper>
          <ClipsGrid>
            <VideoSearchHitsList />
            <RafinementListWrapper>
              <RefinementList attribute="competitions" />
            </RafinementListWrapper>
          </ClipsGrid>
        </VideosPageWrapper>
      </InstantSearch>
    )
  );
};
