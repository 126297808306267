import { LiveCompetitionsListDataType } from "@/shared/types/LiveCompetitionsListDataType";
import { CompetitionsFilterItem } from "@ui/blocks/CompetitionsFilterItem";
import React, { ReactElement } from "react";

interface RenderCompetitionsProps {
  items: Array<LiveCompetitionsListDataType>;
  selectedItem: string;
  onItemClicked: (itemID: string) => void;
}

export const renderCompetitions: (
  obj: RenderCompetitionsProps,
) => Array<ReactElement> = ({ items, onItemClicked, selectedItem }) => {
  return items.map((item, i) => (
    <CompetitionsFilterItem
      key={i}
      text={item.competition_name}
      isSelected={selectedItem === item.competition_id}
      onItemClick={onItemClicked}
      numberOfAvailableMatches={item.number_of_upcoming_games}
      id={item.competition_id}
      icon={{
        src: `https://images.onefootball.com/icons/leagueColoredCompetition/128/${item.competition_id}.png`,
        alt: `${item.competition_name} icon`,
      }}
    />
  ));
};
