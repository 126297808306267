import styled, { css } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { TitleText } from "@ui/elements/TitleText";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";

type HeaderLinkProps = { active: boolean } & WithThemeType;

export const HeaderLink = styled(TitleText)<HeaderLinkProps>`
  ${limit};
  display: flex;
  text-align: center;
  height: 100%;
  padding: 1rem 0;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.border};

  @media all and (${deviceSizeQuery.tabletPortrait}) {
    place-items: center;
    border-bottom: none;
    padding: 0;
    &:hover {
      ${({ active }) => {
        return active
          ? css<HeaderLinkProps>`
              border-bottom: 2px solid ${(p) => p.theme.colors.activeElement};
            `
          : css<HeaderLinkProps>`
              border-bottom: 2px solid ${(p) => p.theme.colors.border};
            `;
      }}
    }

    ${({ active }) => {
      return (
        active &&
        css<HeaderLinkProps>`
          border: ${(p) => p.theme.colors.activeElement};
          color: ${(p) => p.theme.colors.activeElement};
          border-bottom: 2px solid ${(p) => p.theme.colors.activeElement};
        `
      );
    }}
  }
`;
