import styled, { css } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { hex2rgb } from "@/shared/helpers/hex2rgb";

export const ImageGradient = styled.div<WithThemeType>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ theme }) => {
    const { r, g, b } = hex2rgb(theme.colors.background);
    return css`
      background: linear-gradient(90deg, rgba(${r}, ${g}, ${b}, 1) 0%, rgba(${r}, ${g}, ${b}, 0.8) 50%, transparent 100%);
      );
    `;
  }}
`;
