import { VideoCardDataStruct } from "@/shared/types/VideoCardType";
import { XPASectionHeaderStruct } from "@/shared/types/XPASectionHeader";
import { array, boolean, Infer, optional, string, type } from "superstruct";

export const VODdataTypeStruct = type({
  uiKey: string(),
  videoGallery: type({
    sectionHeader: XPASectionHeaderStruct,
    items: optional(array(VideoCardDataStruct)),
  }),
  noMorePages: optional(boolean()),
});

export type VODdataType = Infer<typeof VODdataTypeStruct>;
