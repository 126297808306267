import React, { FC } from "react";
import { TitleWrapper } from "@ui/blocks/LiveCompetitionsFilter/elements/TitleWrapper";
import { TitleText } from "@ui/elements/TitleText";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { TextScreenReader } from "@ui/elements/TextScreenReader";
import { useLocale } from "@/shared/hooks/useLocale";
import { isNullish } from "@/shared/helpers/isNullish";
import { ThemeColor } from "@themeTypes/ThemeColorType";

interface SingleFilterTitleProps {
  title: string;
  isDarkTheme: boolean;
  collapsed: boolean;
  onTitleClick?: () => void;
}

export const SingleFilterTitle: FC<SingleFilterTitleProps> = ({
  isDarkTheme,
  title,
  collapsed,
  onTitleClick,
}) => {
  const { t } = useLocale();
  return (
    <TitleWrapper
      onClick={() => {
        if (!isNullish(onTitleClick)) {
          onTitleClick();
        }
      }}
    >
      <TitleText color={ThemeColor.BodyText} fontWeight={700}>
        {title}
      </TitleText>
      <ImageWithFallBack
        isDarkMode={isDarkTheme}
        src={
          collapsed
            ? "/assets/icons/chevron_down_light.svg"
            : "/assets/icons/chevron_up_light.svg"
        }
        darkModeSrc={
          collapsed
            ? "/assets/icons/chevron_down_dark.svg"
            : "/assets/icons/chevron_up_dark.svg"
        }
        alt={""}
        width={24}
        height={24}
        data-testid={"chevron-icon"}
      />
      <TextScreenReader>
        {collapsed ? t("FILTER_CLOSE_MENU") : t("FILTER_OPEN_MENUS")}
      </TextScreenReader>
    </TitleWrapper>
  );
};
