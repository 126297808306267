import { Link } from "react-router-dom";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { FC } from "react";
import { VerifiedIcon } from "@ui/blocks/VerifiedIcon";
import { isNullish } from "@/shared/helpers/isNullish";
import { Wrapper } from "./elements/Wrapper";
import { Title } from "@ui/blocks/VideoProvider/elements/Title";
import { TitleText } from "@ui/elements/TitleText";
import { FontSize } from "@themeTypes/FontSizeType";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { AppRoutes } from "@/shared/constants/Routes";

export interface VideoProviderProps {
  providerId: string;
  providerName?: string;
  isVerified?: boolean;
  publishTimestamp?: string;
  publishedTime?: string;
  providerLogo?: ImageWithFallBackProps;
}

export const VideoProvider: FC<VideoProviderProps> = (props) => {
  const {
    providerId,
    providerName,
    publishedTime,
    isVerified = false,
    publishTimestamp,
    providerLogo,
  } = props;

  return (
    <Wrapper as={Link} to={`${AppRoutes.Creators}/${providerId}`}>
      {!isNullish(providerLogo) && <ImageWithFallBack {...providerLogo} />}
      <div>
        <Title>
          <TitleText size={FontSize.Title_8} fontWeight={500}>
            {providerName}
          </TitleText>
          {isVerified && <VerifiedIcon />}
        </Title>

        {!isNullish(publishedTime) && publishedTime.length > 0 && (
          <BodyText
            as="time"
            size={FontSize.Title_8}
            fontWeight={500}
            title={publishTimestamp}
            color={ThemeColor.LabelText}
          >
            {publishedTime}
          </BodyText>
        )}
      </div>
    </Wrapper>
  );
};
