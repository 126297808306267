import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackingService } from "@features/tracking/services/trackingService";
import { EventName } from "@/modules/tracking/constants/EventName";

export function usePageTracking(): void {
  /*
  If the user in on the clips page and navigates to another clips page we have to update the stream name and send the event anyway, to make this
  happen we add pathname to the dependencies array
   */
  const { pathname } = useLocation();

  useEffect(() => {
    void trackingService.sendEvent({
      name: EventName.PageView,
      properties: {},
    });
  }, [pathname]);
}
