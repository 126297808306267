import Cookie from "js-cookie";

export type CookieOptions = Readonly<{
  key: string;
  value: string;
  expires: Date;
}>;

export const COOKIE_NAMES: Record<string, string> = {
  userId: "userId",
} as const;

// Asuming that the app is always running on https
const secure = true;

export const cookieService = {
  put(options: CookieOptions): void {
    Cookie.set(options.key, options.value, {
      expires: options.expires,
      sameSite: "strict",
      secure,
    });
  },

  get(key: string): string | undefined {
    return Cookie.get(key);
  },

  remove(key: string): void {
    Cookie.remove(key);
  },
};
