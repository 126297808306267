import { Infer, string, type, number } from "superstruct";

export const CreatorDetailDTOStruct = type({
  provider_id: number(),
  language: string(),
  display_name: string(),
  logo: string(),
  color: string(),
  opacity: number(),
  imprint_url: string(),
  banner_url: string(),
  bio: string(),
  social_link_url: string(),
  followers: number(),
});

export type CreatorDetailDTO = Infer<typeof CreatorDetailDTOStruct>;
