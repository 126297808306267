import { boolean, Infer, optional, string, type } from "superstruct";

export const XPAImageObjectStruct = type({
  path: string(),
  alt: string(),
  darkModePath: optional(string()),
  isPreloaded: optional(boolean()),
});

export type XPAImageObjectType = Infer<typeof XPAImageObjectStruct>;
