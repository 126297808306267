import { CustomAdsParameters } from "@ui/blocks/VideoPlayer/types/CustomAdsParameters";
import { getRecordEntries } from "@ui/blocks/VideoPlayer/helpers/getRecordEntries";
import { camelToSnakeCase } from "@/shared/helpers/camelToSnakeCase";
import { isNullish } from "@/shared/helpers/isNullish";

export const constructAdUrl = (
  backendUrl: string,
  customParams: CustomAdsParameters | undefined,
): string => {
  try {
    const url = new URL(backendUrl);

    //combine customParams from url and explicit ones
    if (!isNullish(customParams)) {
      const urlCustomParamsString = decodeURIComponent(
        url.searchParams.get("cust_params") ?? "",
      );

      const allCustomParams = new URLSearchParams(urlCustomParamsString);

      const custParamRecord: Record<
        | "sku"
        | "rightsId"
        | "providerId"
        | "contentLength"
        | "videoLengthBucket"
        | "contentId"
        | "contentTags",
        string
      > = {
        sku: customParams.sku ?? "",
        rightsId: customParams.rightsId ?? "",
        providerId: customParams.providerId ?? "",
        contentLength: customParams.contentLength ?? "",
        videoLengthBucket: customParams.videoLengthBucket ?? "",
        contentId: customParams.contentId ?? "",
        contentTags: customParams.contentTags ?? "",
      };

      getRecordEntries(custParamRecord).forEach(([key, value]) => {
        allCustomParams.set(camelToSnakeCase(key), value);
      });

      url.searchParams.set("cust_params", allCustomParams.toString());
    }
    return url.toString();
  } catch (e: unknown) {
    return "";
  }
};
