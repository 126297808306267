import React, { FC, useState } from "react";
import { TwoColumnGridLayout } from "@ui/elements/TwoColumnGridLayout";
import { VideosPageWrapper } from "@ui/elements/VideosPageWrapper";
import { FiltersContainer } from "@features/liveMatches/containers/FiltersContainer";
import { LiveMatchCardListContainer } from "@features/liveMatches/containers/LiveMatchesCardListContainer";
import { useAppSelector } from "@/shared/hooks/appHooks";
import { BurgerMenu } from "@ui/blocks/BurgerMenu";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";
import filtersIconLight from "/assets/icons/filter_light.svg";
import filtersIconDark from "/assets/icons/filter_dark.svg";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";

const LiveMatchesPage: FC = () => {
  const [filtersDrawerOpened, setFiltersDrawerOpened] = useState(false);

  useScreenName(ScreenName.LiveMatches);
  usePageTracking();

  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  return (
    <VideosPageWrapper>
      <BurgerMenu
        onBurgerClick={() => setFiltersDrawerOpened(!filtersDrawerOpened)}
        iconSrc={isDarkTheme ? filtersIconDark : filtersIconLight}
        iconAlt="filters-icon"
        absolutePosition={{ right: "1.25rem", top: "5rem", left: "auto" }}
      />
      <TwoColumnGridLayout data-testid="live-matches-container">
        <LiveMatchCardListContainer />
        <FiltersContainer
          filtersDrawerOpened={filtersDrawerOpened}
          onFiltersDrawerOutsideClick={() => setFiltersDrawerOpened(false)}
        />
      </TwoColumnGridLayout>
    </VideosPageWrapper>
  );
};

export { LiveMatchesPage };
