import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/shared/store";

export interface PageLayoutState {
  isDarkTheme: boolean;
}

const initialState: PageLayoutState = {
  isDarkTheme: false,
};

export const pageLayoutSlice = createSlice({
  name: "pageLayout",
  initialState,
  reducers: {
    toggleDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.isDarkTheme = action.payload;
    },
  },
});

export const { toggleDarkTheme } = pageLayoutSlice.actions;

export const selectIsDarkTheme = (state: RootState): boolean =>
  state.pageLayout.isDarkTheme;

export const pageLayoutReducer = pageLayoutSlice.reducer;
