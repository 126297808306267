import React, { FC } from "react";
import { CardSectionTitle } from "@ui/blocks/CardSectionTitle";
import { AppRoutes } from "@/shared/constants/Routes";
import { VODSection } from "@features/discovery/blocks/VODSection";
import { DiscoveryGridItemWrapper } from "@features/discovery/elements/DiscoveryGridItemWrapper";
import { useLocale } from "@/shared/hooks/useLocale";
import { useGetHighlightsRailQuery } from "@features/discovery/services/discoveryApi";
import { ColumnGrid } from "@features/discovery/elements/ColumnGrid";
import { useAppSelector } from "@/shared/hooks/appHooks";
import { selectCurrentPage } from "@features/highlights/store/highlightsSlice";
import { NUMBER_OF_ITEMS_PER_PAGE } from "@features/highlights/constants/HighlightsConstants";
import { VODSectionLoader } from "@features/discovery/blocks/VODSectionLoader";
import {
  GalleryNames,
  viewMoreTrackingEvent,
} from "@ui/blocks/CardSectionTitle/ViewMoreEvent";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { SectionCardWrapper } from "@features/discovery/elements/SectionCardWrapper";
import { trackingService } from "@features/tracking/services/trackingService";

export const HighlightsSectionContainer: FC = () => {
  const { t, locale } = useLocale();
  const currentPage = useAppSelector(selectCurrentPage);
  const { data, isFetching, isSuccess } = useGetHighlightsRailQuery({
    locale,
    offset: currentPage * NUMBER_OF_ITEMS_PER_PAGE,
  });

  return (
    <DiscoveryGridItemWrapper>
      <CardSectionTitle
        linkTo={AppRoutes.Highlights}
        linkText={t("VIEW_MORE")}
        onClick={() => {
          void trackingService.sendEvent(
            viewMoreTrackingEvent(GalleryNames.Highlights),
          );
        }}
      >
        {t("NAVIGATION_HIGHLIGHTS")}
      </CardSectionTitle>
      <ColumnGrid minItemWidth="18.125rem">
        {isFetching ? (
          <SectionCardWrapper>
            <VODSectionLoader
              numberOfItems={8}
              cardsTestID="highlights-cards-loader"
            />
          </SectionCardWrapper>
        ) : (
          isSuccess && (
            <VODSection
              items={data.videoGallery.items}
              numberOfSectionItems={8}
              sectionType={SectionTypes.highlights}
            />
          )
        )}
      </ColumnGrid>
    </DiscoveryGridItemWrapper>
  );
};
