import { Infer, number, string, type } from "superstruct";
import { XPAImageObjectStruct } from "@/shared/types/XPAImageObjectType";

export const StadiumDataTypeStruct = type({
  id: number(),
  city: string(),
  name: string(),
  image: XPAImageObjectStruct,
});

export type StadiumDataType = Infer<typeof StadiumDataTypeStruct>;
