import { isNullish } from "@/shared/helpers/isNullish";
import i18next from "i18next";

export const utcToLocaleDate = (utcTimestamp?: string): string => {
  return !isNullish(utcTimestamp)
    ? new Date(utcTimestamp).toLocaleDateString(i18next.resolvedLanguage, {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "";
};
