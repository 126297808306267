import { FC } from "react";
import { VideoCardText } from "@ui/blocks/VideoCard/elements/VideoCardText";
import { FontSize } from "@themeTypes/FontSizeType";
import { BodyText } from "@ui/elements/BodyText";
import { VideoCardWrapper } from "@ui/blocks/VideoCard/elements/VideoCardWrapper";
import Skeleton from "react-loading-skeleton";

interface VideoCardLoaderProps {
  testID?: string;
}

export const VideoCardLoader: FC<VideoCardLoaderProps> = ({ testID }) => {
  return (
    <VideoCardWrapper to="#" data-testid={testID ?? "video-card-loader"}>
      <Skeleton height={122} style={{ marginBottom: "1rem" }} />
      <div>
        <VideoCardText fontWeight={700} size={FontSize.Title_7}>
          <Skeleton count={2} />
        </VideoCardText>
        <BodyText size={FontSize.BodyText_8}>
          <Skeleton width="60%" />
        </BodyText>
      </div>
    </VideoCardWrapper>
  );
};
