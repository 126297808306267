import { FC } from "react";
import { TemporaryWrapper } from "@ui/elements/TemporaryWrapper";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { TitleText } from "@ui/elements/TitleText";

interface AppErrorProps {
  message: string;
}

export const GenericAppError: FC<AppErrorProps> = ({ message }) => {
  return (
    <TemporaryWrapper role="alert">
      <TitleText color={ThemeColor.BodyText}>Something went wrong:</TitleText>
      <BodyText as="pre" color={ThemeColor.RedCard}>
        {message}
      </BodyText>
    </TemporaryWrapper>
  );
};
