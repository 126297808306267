import styled from "styled-components";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";

export const Limit = styled.div`
  ${limit};
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  gap: 2rem;
`;
