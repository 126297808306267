import { ErrorCause } from "@features/errorBoundary/types/ErrorCauseType";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import { AdBlockerBanner } from "@ui/blocks/AdBlockerBanner";
import { NetworkError } from "@ui/blocks/NetworkError";
import { GenericAppError } from "@ui/blocks/GenericAppError";

interface ErrorFallbackProps extends FallbackProps {
  error: Error;
}

export const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
  // return corresponding fallback component based on error cause
  if (error.cause === ErrorCause.AdBlockerError) {
    return (
      // TODO: text should probably come from the additional props or fetched from outside
      <AdBlockerBanner text="Please, disable ad blocker plugin and refresh the page" />
    );
  }

  if (error.cause === ErrorCause.NetworkError) {
    return <NetworkError />;
  }
  return <GenericAppError message={error.message} />;
};
