import styled from "styled-components";

export const Wrapper = styled.a`
  display: flex;
  height: 2.375em;
  place-items: center;
  gap: 1em;

  @include desktop-and-up {
    height: 2.5em;
  }
`;
