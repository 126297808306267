import { css } from "styled-components";

import DrukFont from "/assets/fonts/Druk/DrukTextLCG-Bold.woff2";

import FormularBold from "/assets/fonts/Formular/Formular-Bold.woff2";
import FormularBoldItalic from "/assets/fonts/Formular/Formular-BoldItalic.woff2";
import FormularItalic from "/assets/fonts/Formular/Formular-Italic.woff2";
import FormularLightItalic from "/assets/fonts/Formular/Formular-LightItalic.woff2";
import FormularLight from "/assets/fonts/Formular/Formular-Light.woff2";
import FormularMedium from "/assets/fonts/Formular/Formular-Medium.woff2";
import FormularRegular from "/assets/fonts/Formular/Formular-Regular.woff2";

export const createFontFaces = css`
  @font-face {
    font-family: "Druk";
    src: url("${DrukFont}");
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularBold}");
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularBoldItalic}");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularItalic}");
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularLightItalic}");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularLight}");
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularMedium}");
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: "Formular";
    src: url("${FormularRegular}");
    font-weight: 400;
    font-display: swap;
  }
`;
