import { FC } from "react";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { VideoDetailPageWrapper } from "@/shared/ui/elements/VideoDetailPageWrapper";
import { CreatorHeaderContainer } from "@/features/creatorDetail/containers/CreatorHeaderContainer";
import { CreatorVideosContainer } from "@/features/creatorDetail/containers/CreatorVideosContainer";
import { VideoCreatorContentWrapper } from "@/features/creatorDetail/elements/VideoCreatorContentWrapper";
import { HorizontalSeparator } from "@/shared/ui/elements/HorizontalLine";
import { VideoCreatorHeader } from "@/features/creatorDetail/elements/VideoCreatorHeader";

export const CreatorDetailPage: FC = () => {
  useScreenName(ScreenName.CreatorDetail);
  usePageTracking();

  return (
    <VideoDetailPageWrapper>
      <VideoCreatorHeader>
        <CreatorHeaderContainer />
      </VideoCreatorHeader>
      <HorizontalSeparator />
      <VideoCreatorContentWrapper>
        <CreatorVideosContainer />
      </VideoCreatorContentWrapper>
    </VideoDetailPageWrapper>
  );
};
