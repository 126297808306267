import styled, { css, keyframes } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import {
  logoSpinnerBarSize,
  logoSpinnerSize,
} from "@ui/blocks/LoadingIcon/elements/Wrapper";

const progressBarIndeterminate = keyframes`
0% {
    transform: rotate(-30deg);
}
29.4% {
    border-left-color: transparent;
}
29.41% {
    border-left-color: currentColor;
}
64.7% {
    border-bottom-color: transparent;
}
64.71% {
    border-bottom-color: currentColor;
}
100% {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg);

}
`;

const progressBarCircular = keyframes`
    0% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(180deg);
        animation-timing-function: linear;
    }
    25% {
        transform: rotate(630deg);
    }
    37.5% {
        transform: rotate(810deg);
        animation-timing-function: linear;
    }
    50% {
        transform: rotate(1260deg);
    }
    62.5% {
        transform: rotate(1440deg);
        animation-timing-function: linear;
    }
    75% {
        transform: rotate(1890deg);
    }
    87.5% {
        transform: rotate(2070deg);
        animation-timing-function: linear;
    }
    100% {
        transform: rotate(2520deg);
    }`;

const indeterminateStyles = css`
  box-sizing: border-box;
  border: solid ${logoSpinnerBarSize} transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: ${progressBarIndeterminate} 2s infinite linear alternate;
`;

export const Progress = styled.progress<WithThemeType>`
  width: ${logoSpinnerSize};
  height: ${logoSpinnerSize};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: currentColor;
  overflow: hidden;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &:indeterminate {
    mask-image: linear-gradient(transparent 50%, black 50%),
      linear-gradient(to right, transparent 50%, black 50%);
    animation: ${progressBarCircular} 16s infinite cubic-bezier(0.3, 0.6, 1, 1);
  }

  &:indeterminate::before {
    ${indeterminateStyles}
  }

  &:indeterminate::-webkit-progress-value {
    ${indeterminateStyles}
  }
  &:indeterminate::-moz-progress-bar {
    ${indeterminateStyles}
  }

  &:indeterminate::before,
  &:indeterminate::-webkit-progress-value {
    content: "";
    display: block;
  }
`;
