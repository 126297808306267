import styled, { css } from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { AbsolutePositionsType } from "@/shared/types/AbsolutePositionsType";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { ZIndexes } from "@/shared/constants/zIndexes";
import { Button } from "@ui/elements/Button";

interface BurgerButtonProps extends WithThemeType {
  position?: Record<AbsolutePositionsType, string>;
}

export const BurgerButton = styled(Button)<BurgerButtonProps>`
  position: absolute;
  left: 20px;
  top: 25px;
  ${(p) =>
    p.position &&
    css`
      top: ${p.position.top || ""};
      right: ${p.position.right || ""};
      bottom: ${p.position.bottom || ""};
      left: ${p.position.left || ""};
    `}
  transition: all 0.3s ease;
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
  cursor: pointer;
  z-index: ${ZIndexes.Above};

  @media all and ${deviceSizeQuery.tabletLandscape} {
    display: none;
  }
`;
