import { boolean, Infer, optional, string, type } from "superstruct";
import { XPAImageObjectStruct } from "@/shared/types/XPAImageObjectType";

export const TeamTypeStruct = type({
  imageObject: XPAImageObjectStruct,
  name: string(),
  score: optional(string()),
  aggregatedScore: optional(string()),
  isNational: optional(boolean()),
  link: optional(string()),
});

export type TeamType = Infer<typeof TeamTypeStruct>;
