import { XPATrackingEventStruct } from "@/shared/types/XPATrackingEvent";
import { XPAImageObjectStruct } from "@/shared/types/XPAImageObjectType";
import { array, boolean, Infer, optional, string, type } from "superstruct";

export const VideoCardDataStruct = type({
  duration: string(),
  imageUrl: optional(string()),
  imageObject: XPAImageObjectStruct,
  link: string(),
  providerName: string(),
  providerLogo: optional(XPAImageObjectStruct),
  publishTime: optional(string()),
  publishTimestamp: optional(string()),
  title: string(),
  trackingEvents: optional(array(XPATrackingEventStruct)),
  uiKey: optional(string()),
  competitionIDs: optional(array(string())),
  matchID: optional(string()),
  playerID: optional(string()),
  teamIDs: optional(array(string())),
  id: optional(string()),
  providerIsVerified: optional(boolean()),
});

export type VideoCardData = Infer<typeof VideoCardDataStruct>;
