import React, { FC } from "react";
import { DiscoveryWrapper } from "@features/discovery/elements/DiscoveryWrapper";
import { LiveMatchesSectionContainer } from "@features/discovery/containers/LiveMatchesSectionContainer";
import { VideoNewsSectionContainer } from "@features/discovery/containers/VideoNewsSectionContainer";
import { HighlightsSectionContainer } from "@features/discovery/containers/HighlightsSectionContainer";
import { TeamNewsSectionContainer } from "@features/discovery/containers/TeamNewsSectionContainer";
import { ScreenName } from "@features/tracking/screen-names";
import { FeaturedMatchContainer } from "@features/featuredMatch/containers/FeaturedMatchContainer";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";

export const DiscoveryPage: FC = () => {
  useScreenName(ScreenName.Home);
  usePageTracking();

  return (
    <div>
      <FeaturedMatchContainer />
      <DiscoveryWrapper data-testid="discovery-container">
        <LiveMatchesSectionContainer />
        <HighlightsSectionContainer />
        <VideoNewsSectionContainer />
        <TeamNewsSectionContainer />
      </DiscoveryWrapper>
    </div>
  );
};
