import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const CardWrapper = styled.div<WithThemeType>`
  display: grid;
  grid-template-rows: auto 1fr max-content;
  gap: 1rem;

  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;
