import { SomeXpaData } from "@/shared/types/SomeXpaData";
import { TransformedXpaMatchDetailData } from "@features/matchDetail/types/TransformedXpaMatchDetailData";
import { normaliseXPAdata } from "@features/matchDetail/helpers/normaliseXPAdata";
import { WithLocaleType } from "@/shared/types/WithLocaleType";
import { langToApiRoute } from "@features/matchDetail/helpers/langToApiRoute";
import { webExperienceApi } from "@/shared/services/webExperienceApi";

interface GetMatchDetailApiProps extends WithLocaleType {
  matchId: string;
}

interface NonValidatedXpaData {
  containers?: SomeXpaData;
}

export const matchDetailApi = webExperienceApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatchDetail: builder.query<
      TransformedXpaMatchDetailData,
      GetMatchDetailApiProps
    >({
      query: ({ locale, matchId }) => `${langToApiRoute(locale)}/${matchId}`,
      // ToDo: remove when dedicated BE is ready
      transformResponse: (response) => {
        const { containers } = response as NonValidatedXpaData;

        if (!containers) {
          throw new Error("Can not resolve xpa data");
        }
        return normaliseXPAdata(containers);
      },
    }),
  }),
});

export const { useGetMatchDetailQuery } = matchDetailApi;
