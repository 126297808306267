import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CREATORS_NETWORK_BASE_URL } from "@/shared/constants/endpoints";

export const creatorsNetworkApi = createApi({
  reducerPath: "creatorsNetworkApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CREATORS_NETWORK_BASE_URL,
  }),
  endpoints: () => ({}),
});
