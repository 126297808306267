import { useTranslation } from "react-i18next";
import { DropDownMenuProps } from "@ui/blocks/DropDownMenu";

const FLAGS_URL = {
  Deutsch: "/assets/icons/flags/german-flag.svg",
  English: "/assets/icons/flags/english-flag.svg",
  Spanish: "/assets/icons/flags/spanish-flag.svg",
  French: "/assets/icons/flags/french-flag.svg",
  Italian: "/assets/icons/flags/italian-flag.svg",
  Portuguese: "/assets/icons/flags/brazilian-flag.svg",
  Indonesian: "/assets/icons/flags/indonesian-flag.svg",
};

export const AVAILABLE_LANGUAGES = {
  Deutsch: "de",
  English: "en",
  Spanish: "es",
  French: "fr",
  Italian: "it",
  Portuguese: "pt-br",
  Indonesian: "id",
};
export const useLanguageMenu = (): DropDownMenuProps => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (language: string) => {
    void i18n.changeLanguage(language);
  };

  const sections = [
    {
      title: "English",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.English),
      icon: FLAGS_URL.English,
      lang: AVAILABLE_LANGUAGES.English,
    },
    {
      title: "Deutsch",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.Deutsch),
      icon: FLAGS_URL.Deutsch,
      lang: AVAILABLE_LANGUAGES.Deutsch,
    },
    {
      title: "Español",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.Spanish),
      icon: FLAGS_URL.Spanish,
      lang: AVAILABLE_LANGUAGES.Spanish,
    },
    {
      title: "Français",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.French),
      icon: FLAGS_URL.French,
      lang: AVAILABLE_LANGUAGES.French,
    },
    {
      title: "Italiano",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.Italian),
      icon: FLAGS_URL.Italian,
      lang: AVAILABLE_LANGUAGES.Italian,
    },
    {
      title: "Português do Brasil",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.Portuguese),
      icon: FLAGS_URL.Portuguese,
      lang: AVAILABLE_LANGUAGES.Portuguese,
    },
    {
      title: "Bahasa Indonesia",
      onClick: () => changeLanguage(AVAILABLE_LANGUAGES.Indonesian),
      icon: FLAGS_URL.Indonesian,
      lang: AVAILABLE_LANGUAGES.Indonesian,
    },
  ];

  const currentLocale = i18n.language;
  const matchedSectionIndex = sections.findIndex(
    (section) => section.lang === currentLocale,
  );

  if (matchedSectionIndex > -1) {
    const matchedSection = sections.splice(matchedSectionIndex, 1)[0];
    sections.unshift(matchedSection);
  }

  return {
    label: t("SELECT_A_LANGUAGE"),
    sections,
  };
};
