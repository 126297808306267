export const FontSize = {
  BodyText_1: "bodyText_1",
  BodyText_2: "bodyText_2",
  BodyText_3: "bodyText_3",
  BodyText_4: "bodyText_4",
  BodyText_5: "bodyText_5",
  BodyText_6: "bodyText_6",
  BodyText_7: "bodyText_7",
  BodyText_8: "bodyText_8",
  Title_1: "title_1",
  Title_2: "title_2",
  Title_3: "title_3",
  Title_4: "title_4",
  Title_5: "title_5",
  Title_6: "title_6",
  Title_7: "title_7",
  Title_8: "title_8",
  DrukTitle_1: "druk_title_1",
  DrukTitle_2: "druk_title_2",
  DrukTitle_3: "druk_title_3",
  DrukTitle_4: "druk_title_4",
  DrukTitle_5: "druk_title_5",
} as const;

export type FontSizeType = (typeof FontSize)[keyof typeof FontSize];
