export const configComscoreAnalytics = (config) => {
  const videoLengthInMs = config.videoLengthInSec * 1000;
  const analytics = ns_.analytics;
  const analyticsConfig = new analytics.configuration.PublisherConfiguration({
    publisherId: "27061346",
  });
  analytics.configuration.addClient(analyticsConfig);
  analytics.configuration.enableFirstPartyCookie();

  // UNCOMMENT FOR DEBUG MODE
  analytics.configuration.enableImplementationValidationMode();

  analytics.start();

  const sa = new analytics.StreamingAnalytics();
  sa.setMediaPlayerName("web-tvhub-videojs");

  const cm = new analytics.StreamingAnalytics.ContentMetadata();

  if (config.videoLengthInSec === 0) {
    cm.setMediaType(
      analytics.StreamingAnalytics.ContentMetadata.ContentType.LIVE,
    );
  } else {
    const VODContentType =
      config.videoLengthInSec < 600
        ? analytics.StreamingAnalytics.ContentMetadata.ContentType
            .SHORT_FORM_ON_DEMAND
        : analytics.StreamingAnalytics.ContentMetadata.ContentType
            .LONG_FORM_ON_DEMAND;
    cm.setMediaType(VODContentType);
  }

  cm.setUniqueId(config.contentId);
  cm.setLength(videoLengthInMs);
  cm.setPublisherName(config.publisherName);
  cm.setProgramTitle(config.programTitle);
  cm.setGenreName("Sports");
  cm.setDictionaryClassificationC6("Onefootball");

  sa.setMetadata(cm);

  sa.createPlaybackSession();
  return sa;
};
