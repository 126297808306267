import { MobileMenuButton } from "@ui/blocks/HeaderTop/elements/MobileMenuIcon";
import { MenuClose } from "@ui/blocks/HeaderTop/elements/MenuClose";
import { TextScreenReader } from "@ui/elements/TextScreenReader";
import { MenuOpen } from "@ui/blocks/HeaderTop/elements/MenuOpen";
import { ImageLink } from "@ui/blocks/HeaderTop/elements/ImageWrapper";
import { AppRoutesType } from "@/shared/constants/Routes";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { LogoWrapper } from "@ui/blocks/HeaderTop/elements/LogoWrapper";
import { FC } from "react";

interface LogoProps {
  isMenuOpen: boolean;
  onMobileMenuClick: () => void;
  screenReaderText: Record<"menuClose" | "menuOpen" | "navigationTo", string>;
  linkTo: AppRoutesType;
  logoImage: ImageWithFallBackProps;
  isDarkMode: boolean;
}

export const Logo: FC<LogoProps> = ({
  isMenuOpen,
  onMobileMenuClick,
  screenReaderText,
  linkTo,
  logoImage,
  isDarkMode,
}) => {
  return (
    <LogoWrapper>
      <MobileMenuButton variant={"icon-only"} onClick={onMobileMenuClick}>
        {isMenuOpen ? (
          <>
            <MenuClose />
            <TextScreenReader>{screenReaderText.menuClose}</TextScreenReader>
          </>
        ) : (
          <>
            <MenuOpen />
            <TextScreenReader>{screenReaderText.menuOpen}</TextScreenReader>
          </>
        )}
      </MobileMenuButton>
      <ImageLink to={linkTo}>
        <ImageWithFallBack {...logoImage} isDarkMode={isDarkMode} />
        <TextScreenReader>{screenReaderText.navigationTo}</TextScreenReader>
      </ImageLink>
    </LogoWrapper>
  );
};
