import { NavMenuAssets } from "@ui/blocks/NavMenu/types/NavMenuAssets";

import burgerMenu from "/assets/icons/burger_menu.svg";
import burgerMenuDark from "/assets/icons/burger_menu_darkMode.svg";
import logoWhite from "/assets/icons/OneFootball_FunctionalLockup_RGB_HypeGreen.png";
import logoBlack from "/assets/icons/OneFootball_FunctionalLockup_RGB_Black.png";
import toggleIconDark from "/assets/icons/darkModeOn.svg";
import toggleIconLight from "/assets/icons/darkModeOff.svg";
import widgetsBurger from "/assets/icons/widgetsSidebarBtn.svg";
import widgetsBurgerDark from "/assets/icons/widgetsSidebarBtnDark.svg";

export const getMenuAssets = (isDarkTheme: boolean): NavMenuAssets => {
  if (isDarkTheme) {
    return {
      burgerIcon: burgerMenuDark,
      logo: logoWhite,
      toggleButtonIcon: toggleIconDark,
      widgetsSidebarBurger: widgetsBurgerDark,
    };
  }

  return {
    burgerIcon: burgerMenu,
    logo: logoBlack,
    toggleButtonIcon: toggleIconLight,
    widgetsSidebarBurger: widgetsBurger,
  };
};
