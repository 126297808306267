import { enums, Infer } from "superstruct";

export const XPAEvent = {
  EVENT_UNSPECIFIED: "EVENT_UNSPECIFIED",
  EVENT_VISIBLE: "EVENT_VISIBLE",
  EVENT_INVISIBLE: "EVENT_INVISIBLE",
  EVENT_CLICK: "EVENT_CLICK",
  SCREEN_VISIBLE: "SCREEN_VISIBLE",
  VIDEO_PLAYED: "VIDEO_PLAYED",
  VIDEO_AD_STARTED: "VIDEO_AD_STARTED",
  UNRECOGNIZED: "UNRECOGNIZED",
};

export const XPAEventStruct = enums(Object.keys(XPAEvent));

export type XPAEventType = Infer<typeof XPAEventStruct>;
