import { envConfig } from "@/shared/helpers/envConfig";
import { PropertiesTypes } from "@features/tracking/services/trackingService";
declare type Rudderanalytics = typeof import("rudder-sdk-js");

const RUDDERSTACK_KEY = envConfig.RUDDERSTACK_KEY;
const RUDDERSTACK_URL = envConfig.RUDDERSTACK_DATA_PLANE;

interface Event {
  name: string;
  properties: Record<string, PropertiesTypes>;
}
interface Tracker {
  init: () => void;
  track: (event: string, properties: Record<string, PropertiesTypes>) => void;
  identify: (userId: string) => void;
  reset: () => void;
}

let rudderanalytics: Rudderanalytics | undefined;
const queue: Array<Event> = [];

const processQueue = () => {
  if (!rudderanalytics) {
    return;
  }

  while (queue.length > 0) {
    const event = queue.shift();
    if (event) {
      rudderanalytics.track(event.name, event.properties);
    }
  }
};

export const rudderstack: Tracker = {
  init: () => {
    if (typeof window === "undefined") {
      throw new Error(`
        Rudderstack should be client-side rendered. 
      `);
    }

    import("rudder-sdk-js")
      .then((sdk) => {
        sdk.load(RUDDERSTACK_KEY, RUDDERSTACK_URL, {
          integrations: { All: true },
        });

        sdk.ready(() => {
          rudderanalytics = sdk;
          processQueue();
        });
      })
      .catch((error) => {});
  },
  track: (event, properties) => {
    if (rudderanalytics === undefined) {
      queue.push({ name: event, properties });
      return;
    }

    rudderanalytics.track(event, properties);
  },
  identify: (userId) => {
    rudderanalytics?.identify(userId);
  },
  reset: () => {
    rudderanalytics?.reset();
  },
};
