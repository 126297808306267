import { SomeXpaData } from "@/shared/types/SomeXpaData";

export const extractDataFromXpa = (
  theObject: SomeXpaData,
  propName: string,
): unknown => {
  let result = null;
  if (theObject instanceof Array) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < theObject.length; i++) {
      result = extractDataFromXpa(theObject[i], propName);
      if (result !== null) {
        break;
      }
    }
  } else {
    for (const prop in theObject) {
      if (prop === propName) {
        return theObject[prop];
      }

      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = extractDataFromXpa(
          theObject[prop] as [] | Record<string, unknown>,
          propName,
        );

        if (result !== null) {
          break;
        }
      }
    }
  }
  return result;
};
