import { WithThemeType } from "@themeTypes/WithThemeType";
import styled, { css } from "styled-components";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";

export const ButtonVariant = {
  action: "action",
  fill: "fill",
  ghost: "ghost",
  icon: "icon",
  iconOnly: "icon-only",
  outline: "outline",
  text: "text",
} as const;

type ButtonVariantType = (typeof ButtonVariant)[keyof typeof ButtonVariant];

type ButtonProps = { variant?: ButtonVariantType } & WithThemeType;

export const Button = styled.button<ButtonProps>`
  appearance: none;
  border: none;
  border-radius: ${(p) => p.theme.roundedCorners.regular};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  min-width: ${GlobalStylesConstants.minSizeInteractiveElement};
  min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
  line-height: 1.25rem;
  text-decoration: none;
  text-align: center;
  transition:
    background 250ms ease-in-out,
    color 250ms ease-in-out,
    box-shadow 250ms ease-in-out,
    opacity 250ms ease-in-out,
    visibility 250ms ease-in-out,
    border-color 250ms ease-in-out;
  cursor: pointer;
  font-weight: 500;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ variant }) =>
    variant === ButtonVariant.ghost &&
    css<ButtonProps>`
        padding: 0.75rem 1.5rem;
        border-radius: ${(p) => p.theme.roundedCorners.regular};
        color: ${(p) => p.theme.colors.background};
        background-color: ${(p) => p.theme.colors.inactiveState};
        &:active {
          background-color: ${(p) => p.theme.colors.inactiveState};
          color: ${(p) => p.theme.colors.background};  
        }
          @media (hover: hover) {
              &:hover:not(:active) {
                  background-color: ${(p) => p.theme.colors.headline};
                  color: ${(p) => p.theme.colors.background}
                  box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
              }
          }`}

  ${({ variant }) =>
    variant === ButtonVariant.fill &&
    css<ButtonProps>`
      padding: 0.75rem 1.5rem;
      border-radius: ${(p) => p.theme.roundedCorners.regular};
      color: ${(p) => p.theme.colors.background};
      background-color: ${(p) => p.theme.colors.headline};
      &:active {
        background-color: ${(p) => p.theme.colors.inactiveState};
        color: ${(p) => p.theme.colors.background};
      }
      @media (hover: hover) {
        &:hover:not(:active) {
          background-color: ${(p) => p.theme.colors.labeltext};
          color: ${(p) => p.theme.colors.background};
          box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
        }
      }
    `},
  
  
  ${({ variant }) =>
    variant === ButtonVariant.outline &&
    css<ButtonProps>`
      padding: 0.75rem 1.5rem;
      border-radius: ${(p) => p.theme.roundedCorners.regular};
      color: ${(p) => p.theme.colors.headline};
      background-color: transparent;
      border: 1px solid ${(p) => p.theme.colors.border};

      &:active {
        background-color: ${(p) => p.theme.colors.inactiveState};
        color: ${(p) => p.theme.colors.background};
      }

      @media (hover: hover) {
        &:hover:not(:active) {
          background-color: ${(p) => p.theme.colors.labeltext};
          color: ${(p) => p.theme.colors.background};
          box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
        }
      }
    `}

  ${({ variant }) =>
    variant === ButtonVariant.text &&
    css<ButtonProps>`
      min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
      min-width: ${GlobalStylesConstants.minSizeInteractiveElement};
      padding: 0;
      color: ${(p) => p.theme.colors.labeltext};
      background-color: transparent;

      @media (hover: hover) {
        &:hover:not(:active) {
          color: ${(p) => p.theme.colors.headline};
          box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
        }
      }
    `}

  ${({ variant }) =>
    variant === ButtonVariant.icon &&
    css<ButtonProps>`
      border-radius: 50%;
      padding: 0;
      min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
      min-width: ${GlobalStylesConstants.minSizeInteractiveElement};
      color: ${(p) => p.theme.colors.headline};
      background-color: ${(p) => p.theme.colors.background};

      &:active {
        background-color: ${(p) => p.theme.colors.inactiveState};
        color: ${(p) => p.theme.colors.headline};
      }

      @media (hover: hover) {
        &:hover:not(:active) {
          color: ${(p) => p.theme.colors.background};
          background-color: ${(p) => p.theme.colors.headline};
          box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
        }
      }
    `}

  ${({ variant }) =>
    variant === ButtonVariant.iconOnly &&
    css<ButtonProps>`
      border-radius: 50%;
      padding: 0;
      min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
      min-width: ${GlobalStylesConstants.minSizeInteractiveElement};
      color: ${(p) => p.theme.colors.headline};
      background-color: transparent;

      &:active {
        color: ${(p) => p.theme.colors.inactiveState};
      }

      @media (hover: hover) {
        &:hover:not(:active) {
          color: ${(p) => p.theme.colors.headline};
          background-color: ${(p) => p.theme.colors.border};
        }
      }
    `}

  ${({ variant }) =>
    variant === ButtonVariant.icon &&
    css<ButtonProps>`
      border-radius: 48px;
      padding: 0.75rem;
      min-height: ${GlobalStylesConstants.minSizeInteractiveElement};
      min-width: ${GlobalStylesConstants.minSizeInteractiveElement};
      color: ${(p) => p.theme.colors.headline};
      background-color: ${(p) => p.theme.colors.elevatedBackground};

      &:active {
        color: ${(p) => p.theme.colors.headline};
        background-color: ${(p) => p.theme.colors.elevatedBackground};
      }

      @media (hover: hover) {
        &:hover:not(:active) {
          color: ${(p) => p.theme.colors.headline};
          background-color: ${(p) => p.theme.colors.elevatedBackground};
        }
      }
        
        &:disabled {
            opacity: 1; 
        }
    }  
    `}
`;
