import { WidgetsSidebarWrapper } from "@ui/elements/WidgetsSidebarWrapper";
import { useOutsideClick } from "@/shared/hooks/useOutsideClick";
import { FC, PropsWithChildren } from "react";

interface WidgetsSidebarProps {
  hasMatch: boolean;
  shouldShowSidebar: boolean;
  onOutsideClick: () => void;
}

export const WidgetsSidebar: FC<PropsWithChildren<WidgetsSidebarProps>> = ({
  children,
  shouldShowSidebar,
  onOutsideClick,
  hasMatch,
}) => {
  const ref = useOutsideClick(() => {
    onOutsideClick();
  });
  return (
    <WidgetsSidebarWrapper
      isSidebarVisible={shouldShowSidebar}
      shouldBeDrawer={hasMatch}
      ref={ref}
    >
      {children}
    </WidgetsSidebarWrapper>
  );
};
