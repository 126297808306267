import { XPAiconObjectStruct } from "@/shared/types/XPAiconObjectType";
import { Infer, optional, string, type } from "superstruct";

export const MatchInfoWidgetEntryStruct = type({
  icon: XPAiconObjectStruct,
  title: string(),
  subtitle: string(),
  uiKey: string(),
  link: optional(type({ name: optional(string()), urlPath: string() })),
});

export type MatchInfoWidgetEntry = Infer<typeof MatchInfoWidgetEntryStruct>;
