export const ONEFOOTBALL_URL = "https://onefootball.com";

export const AppRoutes = {
  Home: "/",
  LiveMatches: "/live-matches",
  Clips: "/clips",
  ClipsDetail: ":id",
  News: "/news",
  Highlights: "/highlights",
  MatchDetail: ":id",
  Creators: "/creators",
  CreatorDetail: ":id",
  Error: "*",
} as const;

export type AppRoutesType = (typeof AppRoutes)[keyof typeof AppRoutes];

export const ExternalRoutes = {
  OFcom: (locale: string): string => `${ONEFOOTBALL_URL}/${locale}/home`,
  LoginPage: (locale: string): string =>
    `${ONEFOOTBALL_URL}/${locale}/payments/sign-in`,
  SignUnPage: (locale: string): string =>
    `${ONEFOOTBALL_URL}/${locale}/payments/sign-up`,
  ProfilePage: (locale: string): string =>
    `${ONEFOOTBALL_URL}/${locale}/payments/profile/following`,
} as const;

export type ExternalRoutesType =
  (typeof ExternalRoutes)[keyof typeof ExternalRoutes];
