import { VideoPlayerDataStruct } from "@/shared/types/VideoPlayerData";
import { Infer, optional, string, type } from "superstruct";

export const LiveMatchDataStruct = type({
  videojsPlayerConfig: VideoPlayerDataStruct,
  description: optional(string()),
  matchId: string(),
  partnerImage: optional(
    type({
      alt: string(),
      path: string(),
    }),
  ),
  partnerName: optional(string()),
  state: optional(string()),
  title: optional(string()),
});

export type LiveMatchDataType = Infer<typeof LiveMatchDataStruct>;
