import styled, { css } from "styled-components";
import { hex2rgb } from "@/shared/helpers/hex2rgb";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { TitleText } from "@ui/elements/TitleText";

export const SectionTitleText = styled(TitleText)<WithThemeType>`
  margin-bottom: 1.25rem;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => {
    const { r, g, b } = hex2rgb(p.theme.colors.bodyText);
    return css`
      border-bottom: 1px solid rgba(${r}, ${g}, ${b}, 0.3);
    `;
  }};
`;
