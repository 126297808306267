import styled from "styled-components";
import { isNullish } from "@/shared/helpers/isNullish";
import type { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

interface ImgProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  width?: number;
  height?: number;
}

export const Img = styled.img<ImgProps>`
  display: flex;
  width: ${(p) => (isNullish(p.width) ? "100%" : `${p.width}px`)};
  height: ${(p) => (isNullish(p.height) ? "100%" : `${p.height}px`)};
`;
