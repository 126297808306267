import {
  ServerParameter,
  ServerParameterType,
  TypedServerParameter,
} from "@/shared/types/TypedServerParameter";
import { isNullish } from "@/shared/helpers/isNullish";
import { PropertiesTypes } from "@features/tracking/services/trackingService";

export const typedServerParametersToObject = (
  typedServerParameters: TypedServerParameter,
): Record<string, PropertiesTypes> => {
  if (isNullish(typedServerParameters)) {
    return {};
  }

  return Object.keys(typedServerParameters).reduce((acc, key) => {
    const parameter = typedServerParameters[key];

    if (
      isNullish(parameter) ||
      isNullish(parameter.type) ||
      isNullish(parameter.value)
    ) {
      return acc;
    }

    const newValue = typedServerParametersParser(
      parameter.type,
      parameter.value,
    );

    return {
      ...acc,
      [key]: newValue,
    };
  }, {});
};

const typedServerParametersParser = (
  type: ServerParameterType,
  value: string,
) => {
  switch (type) {
    case ServerParameter.FLOAT:
      return parseFloat(value);

    case ServerParameter.BOOLEAN:
      return value === "true";

    case ServerParameter.INTEGER:
      return parseInt(value);

    case ServerParameter.STRING:
      return value;

    default:
      return;
  }
};
