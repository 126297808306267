import { Infer, optional, string, type } from "superstruct";

export const OttBannerDataStruct = type({
  message: optional(string()),
  link: optional(type({ name: optional(string()), urlPath: string() })),
  broughtBy: string(),
  logo: optional(
    type({
      alt: string(),
      path: string(),
    }),
  ),
});

export type OttBannerData = Infer<typeof OttBannerDataStruct>;
