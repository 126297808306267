import React, { FC, useState } from "react";
import { SingleFilterWrapper } from "@ui/elements/SingleFilterWrapper";
import { CompetitionsFilterItem } from "@ui/blocks/CompetitionsFilterItem";
import { SingleFilterTitle } from "@ui/blocks/SingleFilterTitle";
import { SingleFilterContent } from "@ui/elements/SingleFilterContent";
import { isNullish } from "@/shared/helpers/isNullish";

export interface PriceFilterProps {
  title: string;
  isDarkTheme: boolean;
  selectedItem: string;
  items: Array<PriceFilterItem>;
  onItemClicked: (itemID: string) => void;
}

interface PriceFilterItem {
  text: string;
  id: string;
}

export const PriceFilter: FC<PriceFilterProps> = (props) => {
  const { title, items, onItemClicked, isDarkTheme, selectedItem } = props;
  const [collapsed, setCollapsed] = useState(true);
  return (
    <SingleFilterWrapper collapsed={collapsed}>
      <SingleFilterContent>
        <SingleFilterTitle
          title={title}
          isDarkTheme={isDarkTheme}
          onTitleClick={() => {
            setCollapsed(!collapsed);
            if (!isNullish(onItemClicked)) {
              onItemClicked("");
            }
          }}
          collapsed={collapsed}
        />
        {items.map((item) => (
          <CompetitionsFilterItem
            key={item.id}
            onItemClick={onItemClicked}
            text={item.text}
            id={item.id}
            isSelected={item.id === selectedItem}
          />
        ))}
      </SingleFilterContent>
    </SingleFilterWrapper>
  );
};
