import styled from "styled-components";
import { TeaserPageWrapper } from "@ui/elements/TeaserPageWrapper";

interface VideoCardsTeaserWrapperProps {
  featured?: boolean;
}

export const VideoCardsTeaserWrapper = styled(
  TeaserPageWrapper,
)<VideoCardsTeaserWrapperProps>`
  grid-template-columns: repeat(auto-fill, minmax(18.125rem, 1fr));
  grid-template-rows: auto;
  overflow: visible;
`;
