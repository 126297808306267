import type { FC } from "react";
import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

const MenuCloseSvg = styled.svg<WithThemeType>`
  color: ${(p) => p.theme.colors.headline};
  width: 24px;
  height: 24px;
`;

export const MenuClose: FC = () => {
  return (
    <MenuCloseSvg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </MenuCloseSvg>
  );
};
