import styled from "styled-components";

interface ColumnGridProps {
  minItemWidth: string;
}

const COLUMN_GRID_GAP = "2.5rem";

export const ColumnGrid = styled.div<ColumnGridProps>`
  display: grid;
  grid-template-columns: ${({ minItemWidth }) => `repeat(
    auto-fill,
    minmax(${minItemWidth}, 1fr) 
  )`};
  gap: ${COLUMN_GRID_GAP};
`;
