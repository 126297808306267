import { VideojsSourceStruct } from "./VideojsSource";
import { DrmPropertiesStruct } from "./DrmProperties";
import { AdsConfigStruct } from "./AdsConfig";
import { YouboraConfigStruct } from "@ui/blocks/VideoPlayer/types/YouboraConfigType";
import { array, boolean, Infer, optional, string, type } from "superstruct";
import { ComscoreMetadataStruct } from "@ui/blocks/VideoPlayer/types/ComscoreMetadata";
import { XPATrackingEventStruct } from "@/shared/types/XPATrackingEvent";

export const VideojsPlaylistItemStruct = type({
  sources: array(VideojsSourceStruct),
  poster: optional(string()),
  isBumper: optional(boolean()),
  ads: optional(array(AdsConfigStruct)),
  heartbeatUrl: optional(string()),
  drm: optional(DrmPropertiesStruct),
  youboraConfig: optional(YouboraConfigStruct),
  comscoreMetadata: optional(ComscoreMetadataStruct),
  trackingEvents: optional(array(XPATrackingEventStruct)),
});

export type VideojsPlaylistItem = Infer<typeof VideojsPlaylistItemStruct>;
