import { FontSizeType } from "@themeTypes/FontSizeType";

export const mobileFontSizes: Record<FontSizeType, string> = {
  druk_title_1: "3.25rem",
  druk_title_2: "2.625rem",
  druk_title_3: "2.25rem",
  druk_title_4: "1.875rem",
  druk_title_5: "1.5rem",
  title_1: "1.875rem",
  title_2: "1.5rem",
  title_3: "1.375rem",
  title_4: "1.25rem",
  title_5: "1.125rem",
  title_6: "1rem",
  title_7: "0.875rem",
  title_8: "0.75rem",
  bodyText_1: "2.25rem",
  bodyText_2: "2rem",
  bodyText_3: "1.5rem",
  bodyText_4: "1.25rem",
  bodyText_5: "1.125rem",
  bodyText_6: "1rem",
  bodyText_7: "0.875rem",
  bodyText_8: "0.75rem",
};

export const desktopFontSizes: Record<FontSizeType, string> = {
  druk_title_1: "5rem",
  druk_title_2: "3.875rem",
  druk_title_3: "3rem",
  druk_title_4: "2.625rem",
  druk_title_5: "2.125rem",
  title_1: "3.5rem",
  title_2: "2.5rem",
  title_3: "2rem",
  title_4: "1.5rem",
  title_5: "1.25rem",
  title_6: "1.125rem",
  title_7: "1rem",
  title_8: "0.875rem",
  bodyText_1: "3.5rem",
  bodyText_2: "2.5rem",
  bodyText_3: "2rem",
  bodyText_4: "1.5rem",
  bodyText_5: "1.25rem",
  bodyText_6: "1.125rem",
  bodyText_7: "1rem",
  bodyText_8: "0.875rem",
};
