import { useLocale } from "@/shared/hooks/useLocale";
import { useGetLiveMatchesRailQuery } from "@features/discovery/services/discoveryApi";
import { LiveMatchCardType } from "@/shared/types/LiveMatchCardType";

interface UseGetFeaturedMatchReturnType {
  featuredMatch: LiveMatchCardType | null;
  isLoading: boolean;
  isSuccess: boolean;
}

export const useGetFeaturedMatch = (): UseGetFeaturedMatchReturnType => {
  const { locale } = useLocale();
  const { data, isLoading, isSuccess } = useGetLiveMatchesRailQuery({
    locale,
    offset: 0,
  });

  const featuredMatch = data?.upcomingMatches?.[0] ?? null;
  return { featuredMatch, isLoading, isSuccess };
};
