import styled, { css } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { hex2rgb } from "@/shared/helpers/hex2rgb";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

interface MatchInfoWrapperProps extends WithThemeType {
  shouldShowScoreInfo: boolean;
}

export const MatchScoreWrapper = styled.div<MatchInfoWrapperProps>`
  position: static;
  top: 1.25rem;
  left: 1.25rem;
  transition: all 0.3s ease;
  ${(p) => {
    const { r, g, b } = hex2rgb(p.theme.colors.background);
    return css`
      background-color: rgba(${r}, ${g}, ${b}, 0.7);
    `;
  }};

  @media all and ${deviceSizeQuery.tabletLandscape} {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    padding: 1rem;
    width: 50%;
    ${(p) =>
      p.shouldShowScoreInfo &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  }
`;
