import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { FontSize } from "@themeTypes/FontSizeType";
import { MatchScoreWrapper } from "@ui/blocks/VideoPlayerRelatedVideos/elements/MatchScoreWrapper";
import { FC } from "react";
import { MatchScoreData } from "@features/matchDetail/types/MatchScoreData";
import { TitleDruk } from "@ui/elements/TitleDruk";

export interface VideoMatchScoreProps {
  shouldShowScore: boolean;
  data: MatchScoreData | null;
}

export const VideoMatchScore: FC<VideoMatchScoreProps> = (props) => {
  const { shouldShowScore, data } = props;
  if (!data) {
    return;
  }
  return (
    <MatchScoreWrapper shouldShowScoreInfo={shouldShowScore}>
      <TitleDruk color={ThemeColor.BodyText} size={FontSize.DrukTitle_5}>
        {data.homeTeam.name} - {data.awayTeam.name}
        <br />
      </TitleDruk>
      <TitleDruk color={ThemeColor.BodyText} size={FontSize.DrukTitle_5}>
        {data.homeTeam.score}:{data.awayTeam.score}
      </TitleDruk>
      <BodyText color={ThemeColor.BodyText} size={FontSize.BodyText_6}>
        {data.timePeriod}
      </BodyText>
    </MatchScoreWrapper>
  );
};
