import { extractDataFromXpa } from "@/shared/helpers/extractDataFromXPA";
import { SomeXpaData } from "@/shared/types/SomeXpaData";
import { TransformedXpaMatchDetailData } from "@features/matchDetail/types/TransformedXpaMatchDetailData";
import { create, nullable } from "superstruct";
import { VideoPlayerDataStruct } from "@/shared/types/VideoPlayerData";
import { RelatedVideosDataStruct } from "@features/matchDetail/types/RelatedVideosDataType";
import { MatchScoreDataStruct } from "@features/matchDetail/types/MatchScoreData";
import { LiveMatchDataStruct } from "@features/matchDetail/types/LiveMatchDataType";
import { MatchInfoWidgetDataStruct } from "@features/matchDetail/types/MatchInfoWidgetData";
import { OttBannerDataStruct } from "@features/matchDetail/types/OttBannerDataType";

export const normaliseXPAdata = (
  data: SomeXpaData,
): TransformedXpaMatchDetailData => {
  return {
    googleAdsPlaceholder: extractDataFromXpa(data, "googleAdsPlaceholder"),
    matchScore: create(
      extractDataFromXpa(data, "matchScore"),
      nullable(MatchScoreDataStruct),
    ),
    videoPlayer: create(
      extractDataFromXpa(data, "videojsPlayer"),
      nullable(VideoPlayerDataStruct),
    ),
    matchEvents: extractDataFromXpa(data, "matchEvents"), // events key
    matchLineup: extractDataFromXpa(data, "matchLineupV2"),
    matchStats: extractDataFromXpa(data, "matchStats"),
    matchPrediction: extractDataFromXpa(data, "matchPrediction"),
    matchInfo: create(
      extractDataFromXpa(data, "matchInfo"),
      MatchInfoWidgetDataStruct,
    ),
    gallery: extractDataFromXpa(data, "gallery"), // related news
    relatedVideos: create(
      extractDataFromXpa(data, "relatedVideos"),
      nullable(RelatedVideosDataStruct),
    ),
    liveMatch: create(
      extractDataFromXpa(data, "matchFreeToAirVideo"),
      nullable(LiveMatchDataStruct),
    ),
    ottBanner: create(
      extractDataFromXpa(data, "ottBanner"),
      nullable(OttBannerDataStruct),
    ),
  };
};
