import { TeamTypeStruct } from "@/shared/types/TeamType";
import { XPAImageObjectStruct } from "@/shared/types/XPAImageObjectType";
import {
  array,
  boolean,
  Infer,
  number,
  optional,
  string,
  type,
} from "superstruct";
import { XPATrackingEventStruct } from "@/shared/types/XPATrackingEvent";
import { MatchCardPriceTypeStruct } from "@/shared/types/MatchCardPriceType";
import { StadiumDataTypeStruct } from "@/shared/types/StadiumDataType";

export const LiveMatchCardStruct = type({
  uiKey: string(),
  link: string(),
  competitionName: string(),
  kickoff: string(),
  timePeriod: string(),
  period: number(),
  homeTeam: TeamTypeStruct,
  awayTeam: TeamTypeStruct,
  trackingEvents: optional(array(XPATrackingEventStruct)),
  ottStreamType: number(),
  matchId: string(),
  kickoffFormatted: string(),
  kickoffTimeFormatted: string(),
  ottBadge: optional(string()),
  competitionLogo: XPAImageObjectStruct,
  partnerLogo: XPAImageObjectStruct,
  partnerName: string(),
  ottThumbnail: XPAImageObjectStruct,
  price: optional(MatchCardPriceTypeStruct),
  stadium: StadiumDataTypeStruct,
  isPurchased: optional(boolean()),
});

export type LiveMatchCardType = Infer<typeof LiveMatchCardStruct>;
