import {
  CreatorDetailDTO,
  CreatorDetailDTOStruct,
} from "@features/creatorDetail/types/CreatorDetailDTO";
import { creatorsNetworkApi } from "@/shared/services/creatorsNetworkApi";
import { create } from "superstruct";

interface GetCreatorDetailApiProps {
  providerId: string;
}

export const creatorDetailApi = creatorsNetworkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCreatorDetail: builder.query<CreatorDetailDTO, GetCreatorDetailApiProps>(
      {
        query: ({ providerId }) => ({
          url: `v1/providers/${providerId}`,
        }),
        transformResponse: (response) => {
          return create(response, CreatorDetailDTOStruct, "can not create");
        },
      },
    ),
  }),
});

export const { useGetCreatorDetailQuery } = creatorDetailApi;
