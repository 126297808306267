import { Infer, number, string, type } from "superstruct";

export const LiveCompetitionsListDataTypeStruct = type({
  competition_id: string(),
  competition_name: string(),
  competition_logo: string(),
  number_of_upcoming_games: number(),
  partner_name: string(),
  partner_logo_url: string(),
});

export type LiveCompetitionsListDataType = Infer<
  typeof LiveCompetitionsListDataTypeStruct
>;
