import { LiveMatchCardLoader } from "@ui/blocks/LiveMatchCard/LiveMatchCardLoader";
import { SectionCardWrapper } from "@features/discovery/elements/SectionCardWrapper";

export const renderLiveMatchesLoading = (
  numberOfItems: number,
): Array<JSX.Element> => {
  return Array.from({ length: numberOfItems }).map((_, i) => {
    return (
      <SectionCardWrapper key={i}>
        <LiveMatchCardLoader />
      </SectionCardWrapper>
    );
  });
};
