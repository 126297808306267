import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const CardImage = styled.div<WithThemeType>`
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: ${(p) => p.theme.roundedCorners.big};
  overflow: hidden;
`;
