import { LiveMatchCardType } from "@/shared/types/LiveMatchCardType";
import { LiveMatchCard } from "@ui/blocks/LiveMatchCard";
import { FC } from "react";
import { EventName } from "@/modules/tracking/constants/EventName";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { SectionCardWrapper } from "@features/discovery/elements/SectionCardWrapper";
import { trackingService } from "@features/tracking/services/trackingService";

interface LiveMatchesSectionProps {
  matchCards: Array<LiveMatchCardType> | undefined;
  numberOfSectionItems: number;
}
export const LiveMatchesSection: FC<LiveMatchesSectionProps> = ({
  matchCards,
  numberOfSectionItems,
}) => {
  if (!matchCards) {
    return [];
  }
  return matchCards.map((card, i) => {
    if (i > numberOfSectionItems - 1) {
      return null;
    }
    return (
      <SectionCardWrapper key={card.uiKey}>
        <LiveMatchCard
          card={card}
          onClick={() =>
            trackingService.sendEvent({
              name: EventName.CardClicked,
              properties: {
                card_type: SectionTypes.match,
                price: card.price?.value,
                item_id: card.matchId,
              },
            })
          }
        />
      </SectionCardWrapper>
    );
  });
};
