import { FC } from "react";

export interface PlayIconProps {
  height?: number;
  width?: number;
}

export const PlayIcon: FC<PlayIconProps> = (props) => {
  const { width = 90, height = 90 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <circle opacity=".9" cx="45" cy="45" r="45" fill="#1A1A24" />
      <path d="M60.8 45 35 29.2v31.6L60.8 45Z" fill="#fff" />
    </svg>
  );
};
