import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const Slider = styled.span<WithThemeType>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(p) => p.theme.colors.navigationBackground};
  transition: 0.3s ease;
  border-radius: 2.125rem;
`;
