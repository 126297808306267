import { FontSize, FontSizeType } from "@themeTypes/FontSizeType";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ThemeColorType } from "@themeTypes/ThemeColorType";
import { SectionTitleText } from "@ui/blocks/CardSectionTitle/elements/SectionTitleText";
import { BodyText } from "@ui/elements/BodyText";
import { isNullish } from "@/shared/helpers/isNullish";

export interface SectionTitleProps {
  linkTo: string;
  linkSize?: FontSizeType;
  linkColor?: ThemeColorType;
  linkText?: string;
  onClick?: () => void;
}

export const CardSectionTitle: FC<PropsWithChildren<SectionTitleProps>> = ({
  children,
  linkTo,
  linkSize,
  linkColor,
  linkText,
  onClick,
}) => {
  return (
    <SectionTitleText size={FontSize.Title_4}>
      {children}
      <BodyText
        as={Link}
        fontWeight={500}
        to={linkTo}
        size={linkSize}
        color={linkColor}
        onClick={(e) => {
          if (!isNullish(onClick)) {
            onClick();
          }
        }}
      >
        {linkText ?? "View more"}
      </BodyText>
    </SectionTitleText>
  );
};
