import {
  XPALiveMatchesDataStruct,
  XPALiveMatchesDataType,
} from "@/shared/types/LiveMatchesDataType";
import { VODdataType, VODdataTypeStruct } from "@/shared/types/VODdataType";
import { WithOffsetType } from "@/shared/types/WithOffsetType";
import { array, assert, create } from "superstruct";
import { GetLiveMatchesRailParams } from "@features/discovery/types/GetLiveMatchesRailParams";
import { liveMatchesInfiniteScrollMerger } from "@features/discovery/services/utils/liveMatchesInfiniteScrollMerger";
import { highlightsInfiniteScrollMerger } from "@features/discovery/services/utils/highlightsInfiniteScrollMerger";
import { webExperienceApi } from "@/shared/services/webExperienceApi";

export const discoveryApi = webExperienceApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeamsRail: builder.query<Array<VODdataType>, WithOffsetType>({
      query: ({ locale, offset }) => `${locale}/tv-hub/teams`,
      transformResponse: (baseQueryReturnValue) =>
        create(baseQueryReturnValue, array(VODdataTypeStruct)),
    }),
    getHighlightsRail: builder.query<VODdataType, WithOffsetType>({
      query: ({ locale, offset = 0 }) => ({
        url: `${locale}/tv-hub/highlights`,
        params: {
          pagination: offset,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { offset, ...rest } = queryArgs;
        return rest;
      },
      transformResponse: (baseQueryReturnValue) =>
        create(baseQueryReturnValue, VODdataTypeStruct, "can not create"),
      merge: highlightsInfiniteScrollMerger,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.offset !== previousArg?.offset,
    }),
    getLiveMatchesRail: builder.query<
      XPALiveMatchesDataType,
      GetLiveMatchesRailParams
    >({
      query: ({ locale, offset = 0, competitionID, price }) => ({
        url: `${locale}/tv-hub/v2/upcoming-matches`,
        params: {
          pagination: offset,
          competitionIDs: competitionID,
          price,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { offset, ...rest } = queryArgs;
        return rest;
      },
      transformResponse: (baseQueryReturnValue) => {
        assert(baseQueryReturnValue, XPALiveMatchesDataStruct);
        return create(
          baseQueryReturnValue,
          XPALiveMatchesDataStruct,
          "can not create",
        );
      },
      merge: liveMatchesInfiniteScrollMerger,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.offset !== previousArg?.offset,
    }),
    getNewsRail: builder.query<VODdataType, WithOffsetType>({
      query: ({ locale }) => `${locale}/tv-hub/news`,
      serializeQueryArgs: ({ queryArgs }) => {
        const { offset, ...rest } = queryArgs;
        return rest;
      },
      transformResponse: (baseQueryReturnValue) =>
        create(baseQueryReturnValue, VODdataTypeStruct, "can not create"),
    }),
  }),
});

export const {
  useGetTeamsRailQuery,
  useGetHighlightsRailQuery,
  useGetNewsRailQuery,
  useGetLiveMatchesRailQuery,
} = discoveryApi;
