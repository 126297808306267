import { DefaultThemeType } from "styled-components";

interface SkeletonsConfigReturnType {
  baseColor: string;
  highlightColor: string;
}

export const getSkeletonsConfig = (
  theme: DefaultThemeType,
): SkeletonsConfigReturnType => ({
  baseColor: theme.colors.border,
  highlightColor: theme.colors.background,
});
