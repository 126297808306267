import {
  boolean,
  enums,
  Infer,
  number,
  record,
  string,
  union,
} from "superstruct";

export const YouboraValueCase = {
  StringValue: "stringValue",
  IntValue: "intValue",
  BoolValue: "boolValue",
  DoubleValue: "doubleValue",
};

export const YouboraValueCaseStruct = enums(Object.values(YouboraValueCase));
export const YouboraConfigStruct = record(
  string(),
  record(YouboraValueCaseStruct, union([string(), number(), boolean()])),
);

export type YouboraValueCaseType = Infer<typeof YouboraValueCaseStruct>;
export type YouboraConfigType = Infer<typeof YouboraConfigStruct>;
