import { Progress } from "./elements/Progress";
import { Wrapper } from "./elements/Wrapper";
import { Icon } from "./elements/Icon";
import { FC } from "react";

export const LogoLoadingSpinner: FC = (props) => {
  return (
    <Wrapper>
      <Progress></Progress>
      <Icon fill="none" viewBox="0 0 44 40" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M26.278.769l-13.23 38.014h9.545L35.823.769h-9.545zM43.538 31.375c0 4.341-3.457 7.855-7.729 7.855s-7.729-3.514-7.729-7.855c0-4.342 3.457-7.855 7.73-7.855 4.257 0 7.728 3.513 7.728 7.855zM15.505.769C14.237 5.422 9.458 9.465 3.411 8.556l-2.95 8.48C14.81 18.811 21.245 11.648 24.809.768h-9.304z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </Icon>
    </Wrapper>
  );
};
