import { FC } from "react";
import { Wrapper } from "./elements/Wrapper";
import { PlayIcon } from "@ui/blocks/PlayIcon";
import { isNullish } from "@/shared/helpers/isNullish";
import { TextScreenReader } from "@ui/elements/TextScreenReader";
import { Icon } from "@ui/blocks/VideoPlayerEmpty/elements/Icon";
import { PlayButton } from "@ui/blocks/VideoPlayerEmpty/elements/PlayButton";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { ONEFOOTBALL_URL } from "@/shared/constants/Routes";

export interface VideoPlayerEmptyProps {
  onClick?: () => void;
  link?: {
    url?: string;
    name?: string;
  };
}

/**
 * At the moment the empty players is meant to be used when the match is a PPV match and we do not have a video player to show,
 * for this reason we need to create a container, also as an initial implementation when clicking the play button it should redirect the user to
 * the checkout page as if the user had clicked "buy now"
 */
export const VideoPlayerEmpty: FC<VideoPlayerEmptyProps> = (props) => {
  const { onClick, link } = props;

  return (
    <Wrapper>
      <ImageWithFallBack
        src={"https://images.onefootball.com/cw/bg/zigzag-pattern-banner.svg "}
        alt={""}
        style={{
          objectPosition: "center",
          objectFit: "fill",
        }}
      ></ImageWithFallBack>
      {!isNullish(link) ? (
        <Icon>
          <PlayButton
            variant={"icon-only"}
            as={"a"}
            href={!isNullish(link.url) ? `${ONEFOOTBALL_URL}/${link.url}` : ""}
            onClick={onClick}
          >
            <TextScreenReader>{link.name}</TextScreenReader>
            <PlayIcon></PlayIcon>
          </PlayButton>
        </Icon>
      ) : (
        <Icon>
          <PlayIcon></PlayIcon>
        </Icon>
      )}
    </Wrapper>
  );
};
