import type { google } from "@alugha/ima";
import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { isNullish } from "@/shared/helpers/isNullish";
import {
  ServerParameter,
  TypedServerParameter,
} from "@/shared/types/TypedServerParameter";
import { trackingService } from "@features/tracking/services/trackingService";
import { typedServerParametersToObject } from "@features/tracking/services/transformTypedServerParams";

interface ImaTrackingEventProps {
  player: VideoJSPlayer;
  event: google.ima.AdEvent;
}

const sendImaTrackingEvents = ({
  player,
  event,
}: ImaTrackingEventProps): void => {
  const currentPlaylistItem = !isNullish(player.playlist?.currentItem)
    ? player.playlist()[player.playlist.currentItem()]
    : undefined;

  const trackingEvents =
    currentPlaylistItem?.ads?.[0]?.adTrackingEvents ??
    player.customAdsConfig?.[0].adTrackingEvents;

  if (isNullish(trackingEvents) || trackingEvents.length === 0) {
    return;
  }

  const currentAd = event.getAd();

  if (currentAd) {
    const adPodInfo = currentAd.getAdPodInfo();
    const adDuration = currentAd.getDuration().toString();

    let adType: string;
    const adTypeNumber = adPodInfo.getPodIndex();
    switch (adTypeNumber) {
      case 0:
        adType = "preroll";
        break;
      case -1:
        adType = "postroll";
        break;
      default:
        adType = "midroll";
        break;
    }

    const podPosition = adPodInfo.getAdPosition().toString();

    const adUnitId = currentAd.getAdId();

    trackingEvents.forEach((trackingEvent) => {
      const clientAdParametersTyped: TypedServerParameter = {
        ad_duration: {
          type: ServerParameter.INTEGER,
          value: adDuration,
        },
        ad_type: {
          type: ServerParameter.STRING,
          value: adType,
        },
        ad_unit_id: {
          type: ServerParameter.STRING,
          value: adUnitId,
        },
        pod_position: {
          type: ServerParameter.STRING,
          value: podPosition,
        },
        network_name: {
          type: ServerParameter.STRING,
          value: "Google",
        },
      };

      const eventProps = typedServerParametersToObject({
        ...clientAdParametersTyped,
        ...trackingEvent.typedServerParameter,
      });

      void trackingService.sendEvent({
        ...trackingEvent,
        properties: { ...eventProps },
      });
    });
  }
};

export { sendImaTrackingEvents };
