import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { VideoPlayerData } from "@/shared/types/VideoPlayerData";
import { MutableRefObject } from "react";
import { UseFullscreen } from "@/shared/hooks/useFullScreen";
import { isNullish } from "@/shared/helpers/isNullish";
import { XPAEvent } from "@ui/blocks/VideoPlayer/types/XPAEventType";
import {
  ServerParameter,
  TypedServerParameter,
} from "@/shared/types/TypedServerParameter";
import { ClientParameter } from "@ui/blocks/VideoPlayer/types/ClientParameterRequest";
import { trackingService } from "@features/tracking/services/trackingService";
import { EventName } from "@/modules/tracking/constants/EventName";
import { typedServerParametersToObject } from "@features/tracking/services/transformTypedServerParams";

interface VideoPlayedEventProps {
  player: VideoJSPlayer | null;
  duration: number;
  fullScreenRef: MutableRefObject<UseFullscreen>;
  wasFinished: boolean;
  options: VideoPlayerData;
}

const sendVideoPlayedEvent = ({
  player,
  options,
  duration,
  fullScreenRef,
  wasFinished,
}: VideoPlayedEventProps): void => {
  if (isNullish(player)) {
    return;
  }
  const itemIndex = player.playlist?.currentItem() ?? -1;
  const playlistItem = options.playlist[itemIndex];

  if (isNullish(playlistItem) || isNullish(playlistItem.trackingEvents)) {
    return;
  }

  const videoPlayedTrackingEvent = playlistItem.trackingEvents.find(
    (event) => event.type === XPAEvent.VIDEO_PLAYED,
  );

  if (isNullish(videoPlayedTrackingEvent)) {
    return;
  }

  const { typedServerParameter, clientParameterRequests } =
    videoPlayedTrackingEvent;

  const clientParameters =
    clientParameterRequests?.reduce<TypedServerParameter>(
      (record, parameter) => {
        // converting client side parameters to typed parameters
        switch (parameter.type) {
          case ClientParameter.PARAMETER_VIDEO_PLAYBACK_DURATION_SECONDS:
            record[parameter.key] = {
              type: ServerParameter.INTEGER,
              value: duration.toString(),
            };

            break;
          case ClientParameter.PARAMETER_VIDEO_ENTERED_FULLSCREEN_MODE:
            record[parameter.key] = {
              type: ServerParameter.BOOLEAN,
              value: fullScreenRef.current.wasFullScreen.toString(),
            };

            break;
          case ClientParameter.PARAMETER_VIDEO_FINISHED:
            record[parameter.key] = {
              type: ServerParameter.BOOLEAN,
              value: wasFinished.toString(),
            };

            break;

          default:
            break;
        }

        return record;
      },
      {},
    );

  void trackingService.sendEvent({
    name: EventName.VideoPlayed,
    properties: typedServerParametersToObject({
      ...typedServerParameter,
      ...clientParameters,
    }),
  });
};

export { sendVideoPlayedEvent };
