import { createGlobalStyle } from "styled-components";

import { WithThemeType } from "@themeTypes/WithThemeType";
import { createFontFaces } from "@ui/elements/GlobalStyles/helpers/createFontFaces";

export const GlobalStyles = createGlobalStyle<WithThemeType>`
    ${createFontFaces}
    
    * {
        box-sizing: border-box;
        scrollbar-width: none;
        padding: 0;
        margin: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    ul, li {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    img {
        max-width: 100%;
    }

    body {
        font-family: 'Formular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${(p) => p.theme.colors.background};
        color: ${(p) => p.theme.colors.headline};
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
  
    button {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
        text-align: inherit;
        font: inherit;
        border-radius: 0;
    }

    // Remove cookie consent preferences floating button
    // and other style fixes for the cookie consent
    // --------
    
    body {
        #ot-sdk-btn-floating {
            display: none;
        }

        #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
            color: inherit;
            border: none;
            padding: 0;
            font-size: 0.875rem;

            &:hover {
                color: inherit;
                background: none;
            }
        }
    }

    // ---------
    // End style fixes for cookie consent
`;
