import { ImageWithFallBackProps } from "@ui/blocks/ImageWithFallBack";
import { FC } from "react";
import { isNullish } from "@/shared/helpers/isNullish";
import { Wrapper } from "@ui/blocks/OttBanner/elements/Wrapper";
import { Description } from "@ui/blocks/OttBanner/elements/Description";
import { ProviderLogo } from "@ui/blocks/OttBanner/elements/ProviderLogo";
import { TitleText } from "@ui/elements/TitleText";
import { CtaButton } from "@ui/blocks/OttBanner/elements/CtaButton";
import { ONEFOOTBALL_URL } from "@/shared/constants/Routes";

export interface OttBannerProps {
  message?: string;
  link?: {
    url: string | undefined;
    name: string | undefined;
  };
  broughtBy?: string;
  logo?: ImageWithFallBackProps;
  onClick?: () => void;
}

export const OttBanner: FC<OttBannerProps> = (props) => {
  const { link, logo, broughtBy, onClick, message } = props;
  return (
    <Wrapper>
      <Description>
        <TitleText>{message}</TitleText>
        <TitleText>{broughtBy}</TitleText>
        {!isNullish(logo) && !isNullish(logo.src) && (
          <ProviderLogo src={logo.src} alt={logo.alt} width={20} height={20} />
        )}
      </Description>
      {!isNullish(link) && (
        <CtaButton
          as={"a"}
          href={!isNullish(link.url) ? `${ONEFOOTBALL_URL}/${link.url}` : ""}
          variant="fill"
          onClick={onClick}
        >
          <TitleText>{link.name}</TitleText>
        </CtaButton>
      )}
    </Wrapper>
  );
};
