import { Button } from "@ui/elements/Button";
import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";

export const MobileMenuButton = styled(Button)`
  width: ${GlobalStylesConstants.minSizeInteractiveElement};
  height: ${GlobalStylesConstants.minSizeInteractiveElement};
  @media all and (${deviceSizeQuery.tabletPortrait}) {
    display: none;
  }
`;
