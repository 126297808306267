import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import styled from "styled-components";

export const ArrowNav = styled.div`
    margin-left: auto;
    @media all and (${deviceSizeQuery.tabletPortrait}) {
        display: none;   
    }
  }
`;
