import { FC } from "react";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { Wrapper } from "@ui/blocks/VideoDescription/elements/Wrapper";
import { HorizontalSeparator } from "@ui/elements/HorizontalLine";
import { TitleText } from "@ui/elements/TitleText";
import { FontSize } from "@themeTypes/FontSizeType";
import { BodyText } from "@ui/elements/BodyText";
import { Footer } from "@ui/blocks/VideoDescription/elements/Footer";
import { State } from "@ui/blocks/VideoDescription/elements/State";
import { isNullish } from "@/shared/helpers/isNullish";
import { Nullable } from "@/shared/types/Nullable";

export interface VideoDescriptionProps {
  title?: string;
  description?: string;
  state?: string;
  partnerName?: string;
  partnerImage?: Nullable<ImageWithFallBackProps>;
}

export const VideoDescription: FC<VideoDescriptionProps> = (props) => {
  const { title, description, partnerImage, partnerName, state } = props;

  return (
    <Wrapper>
      <TitleText size={FontSize.Title_7}>{title}</TitleText>
      <HorizontalSeparator />
      <BodyText size={FontSize.BodyText_8}>{description}</BodyText>
      <Footer>
        {!isNullish(partnerImage) && (
          <ImageWithFallBack {...partnerImage} width={32} height={32} />
        )}
        <BodyText size={FontSize.BodyText_8}>{partnerName}</BodyText>
        <State size={FontSize.BodyText_8} fontWeight={700}>
          {state}
        </State>
      </Footer>
    </Wrapper>
  );
};
