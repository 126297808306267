import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const logoSpinnerSize = "6.25rem";
export const logoSpinnerBarSize = "0.25rem";

export const Wrapper = styled.div<WithThemeType>`
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  border: ${logoSpinnerBarSize} solid ${(props) => props.theme.colors.border};
  border-radius: 50%;
  width: ${logoSpinnerSize};
  height: ${logoSpinnerSize};
  color: ${(props) => props.theme.colors.activeElement};
  filter: drop-shadow(${GlobalStylesConstants.shadowEffect});

  @media all and ${deviceSizeQuery.desktop} {
    --logo-spinner-size: 8.75rem;
  }
`;
