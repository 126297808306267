import styled from "styled-components";
import { BodyText } from "@ui/elements/BodyText";

export const VideoCardText = styled(BodyText)`
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0.375rem;
`;
