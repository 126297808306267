import styled from "styled-components";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { ZIndexes } from "@/shared/constants/zIndexes";

export const ContentWrapper = styled.div<WithThemeType>`
  ${limit};
  position: relative;
  z-index: ${ZIndexes.Above};
  width: 100%;
`;
