import videojs from "video.js";
import { VideojsPlayerConfig } from "../types/VideoJSPlayerConfig";
import { VideoJSPlayer } from "../types/VideoJSPlayer";
import { VideojsPlaylistItem } from "../types/VideoPlaylistItem";
import { configPlaylistWithDRM } from "@ui/blocks/VideoPlayer/services/configPlaylistWithDRM";
import { constructAdUrl } from "@ui/blocks/VideoPlayer/helpers/constructAdUrl";
import { isNullish } from "@/shared/helpers/isNullish";
import { configVideoAnalytics } from "@ui/blocks/VideoPlayer/services/configVideoAnalytics";
import { create, string } from "superstruct";

export const initVideoPlayer = (
  placeholderElement: HTMLDivElement,
  config: VideojsPlayerConfig,
  playlist: Array<VideojsPlaylistItem>,
): VideoJSPlayer | null => {
  // is player already initialised check
  if (placeholderElement.hasChildNodes()) {
    return null;
  }
  const currentVideoEl = placeholderElement.appendChild(
    document.createElement("video"),
  );
  currentVideoEl.classList.add("video-js");

  const player: VideoJSPlayer = videojs(currentVideoEl, config, async () => {
    configPlaylistWithDRM(player, playlist);

    // Attach ads
    if (!isNullish(playlist[0].ads)) {
      const adTagUrl = constructAdUrl(
        playlist[0].ads[0].urls[0],
        playlist[0].ads[0].customParameters,
      );

      if (player.ima) {
        player.ima({
          adTagUrl,
        });
      }
    }

    try {
      await configVideoAnalytics({
        player,
        config: playlist[0]?.youboraConfig ?? {},
        npawAccountCode: create(
          import.meta.env.VITE_NPAW_CODE,
          string(),
          "Can not import NPAW account code",
        ),
      });
    } catch (error) {
      // Fails in silence and sadness
    }
  });

  return player;
};
