import { FooterProps } from "@ui/blocks/Footer";
import home from "/assets/icons/OFLongIconWhite.svg";
import homeDark from "/assets/icons/OFLongIconDark.svg";

export const FooterConfig: FooterProps = {
  title: "Follow OneFootball",
  icon: {
    src: home,
    darkModeSrc: homeDark,
    alt: "home",
    width: 150,
    height: 24,
  },
  sections: [
    {
      title: "FOOTER_ABOUT_US",
      links: [
        { title: "FOOTER_COMPANY_TITLE", url: "FOOTER_COMPANY_URL" },
        { title: "FOOTER_CAREERS", url: "FOOTER_CAREERS_URL" },
        { title: "FOOTER_CONTACT", url: "FOOTER_CONTACT_URL" },
        {
          title: "FOOTER_HELP_CENTER",
          url: "FOOTER_HELP_CENTER_URL",
        },
      ],
    },
    {
      title: "FOOTER_PARTNER_WITH_US",
      links: [
        { title: "FOOTER_SALES", url: "FOOTER_SALES_URL" },
        { title: "FOOTER_CLFP", url: "FOOTER_CLFP_URL" },
        { title: "FOOTER_PUBLISHER", url: "FOOTER_PUBLISHER_URL" },
        {
          title: "FOOTER_BRAND_SOLUTIONS",
          url: "FOOTER_BRAND_SOLUTIONS_URL",
        },
      ],
    },
    {
      title: "FOOTER_LEGAL",
      links: [
        {
          title: "FOOTER_IMPRINT",
          url: "FOOOTER_IMPRINT_URL",
        },
        {
          title: "FOOTER_PRIVACY_POLICY",
          url: "FOOTER_PRIVACY_POLICY_URL",
        },
        {
          title: "FOOTER_TERMS_AND_CONDITIONS",
          url: "FOOTER_TERMS_AND_CONDITIONS_URL",
        },
        {
          title: "FOOTER_EN_DS_INFORMATION",
          url: "FOOTER_EN_DS_INFORMATION_URL",
        },
      ],
    },
    {
      title: "FOOTER_MORE_IN_OF",
      links: [
        {
          title: "App Store",
          url: "https://apps.apple.com/us/app/onefootball/id382002079",
        },
        {
          title: "Google Play",
          url: "https://play.google.com/store/apps/details?id=de.motain.iliga",
        },
        {
          title: "OneFootball Merch",
          url: "https://store.onefootball.com/",
        },
        {
          title: "TV App",
          url: "https://company.onefootball.com/tv-app/",
        },
      ],
    },
  ],
  socialMedia: [
    {
      title: "Instagram",
      url: "https://www.instagram.com/onefootball",
      handle: "@onefootball",
      icon: {
        src: "https://images.onefootball.com/cw/icons/instagram_light.svg",
        alt: "Instagram icon",
      },
    },
    {
      title: "Facebook",
      url: "https://www.facebook.com/OneFootball",
      handle: "@OneFootball.de",
      icon: {
        src: "https://images.onefootball.com/cw/icons/facebook_light.svg",
        alt: "Facebook icon",
      },
    },
    {
      title: "Twitter",
      url: "https://twitter.com/Onefootball",
      handle: "@OneFootball",
      icon: {
        src: "https://images.onefootball.com/cw/icons/twitter_light.svg",
        alt: "Twitter icon",
      },
    },
    {
      title: "Youtube",
      url: "https://www.youtube.com/user/Onefootball",
      handle: "@OneFootball",
      icon: {
        src: "https://images.onefootball.com/cw/icons/youtube_light.svg",
        alt: "Youtube icon",
      },
    },
    {
      title: "TikTok",
      url: "https://www.tiktok.com/@onefootball",
      handle: "@onefootball",
      icon: {
        src: "https://images.onefootball.com/cw/icons/tiktok_light.svg",
        alt: "TikTok icon",
      },
    },
    {
      title: "AudioBoom",
      url: "https://audioboom.com/channel/onefootball",
      handle: "@OneFootball",
      icon: {
        src: "https://images.onefootball.com/cw/icons/audioboom_light.svg",
        alt: "AudioBoom icon",
      },
    },
  ],
};
