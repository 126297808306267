import styled, { css } from "styled-components";
import { BodyText } from "@ui/elements/BodyText";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { hex2rgb } from "@/shared/helpers/hex2rgb";

export const BannerText = styled(BodyText)<WithThemeType>`
  position: absolute;
  padding: 3rem;
  ${({ theme }) => {
    const { r, g, b } = hex2rgb(theme.colors.background);
    return css`
      background: rgba(${r}, ${g}, ${b}, 0.9);
    `;
  }}
`;
