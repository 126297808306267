import type {
  Store,
  PreloadedStateShapeFromReducersMapObject,
} from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { videoPlayerReducer } from "@features/videoPlayer/store/videoPlayerSlice";
import { pageLayoutReducer } from "@features/pageLayout/store/pageLayoutSlice";
import { liveMatchesReducer } from "@features/liveMatches/store/liveMatchesSlice";
import { highlightsReducer } from "@features/highlights/store/highlightsSlice";
import { webExperienceApi } from "@/shared/services/webExperienceApi";
import { authenticationReducer } from "@features/authentication";
import { geolocationApi } from "@features/tracking/services/geolocationApi";
import { screenNameReducer } from "@features/tracking/store/screenNameSlice";
import { creatorsNetworkApi } from "@/shared/services/creatorsNetworkApi";

const rootReducer = combineReducers({
  videoPlayer: videoPlayerReducer,
  pageLayout: pageLayoutReducer,
  liveMatches: liveMatchesReducer,
  highlights: highlightsReducer,
  [webExperienceApi.reducerPath]: webExperienceApi.reducer,
  authentication: authenticationReducer,
  [geolocationApi.reducerPath]: geolocationApi.reducer,
  screenName: screenNameReducer,
  [creatorsNetworkApi.reducerPath]: creatorsNetworkApi.reducer,
});

export const setupStore = (
  preloadedState?: PreloadedStateShapeFromReducersMapObject<RootState>,
): Store<RootState> => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(webExperienceApi.middleware)
        .concat(creatorsNetworkApi.middleware)
        .concat(geolocationApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

/*
    //Thunks currently not in use, added code for reference
    export type AppThunk<ReturnType = void> = ThunkAction<
      ReturnType,
      RootState,
      unknown,
      Action
    >;
*/

export const store = setupStore();
