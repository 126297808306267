import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { Link } from "react-router-dom";

export const VideoCardWrapper = styled(Link).withConfig({
  shouldForwardProp: () => true,
})`
  width: 100%;
  height: 15.625rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  @media all and ${deviceSizeQuery.tabletPortrait} {
    height: 12.5rem;
  }
`;
