import { FC } from "react";
import type { DetailedHTMLProps, AnchorHTMLAttributes } from "react";
import { Img } from "@ui/elements/Image";
import { useImageWithFallback } from "@/shared/hooks/useImageWithFallback";
import { isNullish } from "@/shared/helpers/isNullish";
import { getImageServiceSrc } from "@ui/blocks/ImageWithFallBack/helper/getImageServiceSrc";

export type ImageWithFallBackProps = {
  width?: number;
  src?: string;
  isDarkMode?: boolean;
  darkModeSrc?: string;
  isPreloaded?: boolean;
  fallBackSrc?: string;
  fallbackDarkSrc?: string;
  alt?: string;

  height?: number;
} & DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const ImageWithFallBack: FC<ImageWithFallBackProps> = ({
  alt,
  width,
  height,
  isPreloaded = false,
  src,
  fallBackSrc,
  fallbackDarkSrc,
  darkModeSrc,
  isDarkMode = false,
  ...restProps
}) => {
  const { imageSrc, handleError } = useImageWithFallback({
    src,
    darkModeSrc,
    fallBackSrc,
    fallbackDarkSrc,
    isDarkMode,
  });

  if (isNullish(imageSrc)) {
    return null;
  }

  return (
    <Img
      alt={alt}
      src={getImageServiceSrc({ src: imageSrc, width: width, height: height })}
      loading={isPreloaded ? "eager" : "lazy"}
      width={width}
      height={height}
      onError={handleError}
      {...restProps}
    />
  );
};
