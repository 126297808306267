import styled, { css } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { hex2rgb } from "@/shared/helpers/hex2rgb";
import { ZIndexes } from "@/shared/constants/zIndexes";

interface FiltersWrapperProps extends WithThemeType {
  opened: boolean;
}

export const FiltersWrapper = styled.div<FiltersWrapperProps>`
  position: absolute;
  min-height: 100vh;
  top: 0;
  right: ${({ opened }) => (opened ? 0 : "-100%")};
  display: ${({ opened }) => (opened ? "block" : "none")};
  background: ${({ theme }) => theme.colors.background};
  padding: 2rem;
  transition: all 0.3s ease;
  max-width: 85%;
  z-index: ${ZIndexes.Above};
  ${({ theme }) => {
    const { r, g, b } = hex2rgb(theme.colors.bodyText);
    return css`
      border-left: 1px solid rgba(${r}, ${g}, ${b}, 0.1);
    `;
  }}
  @media all and ${deviceSizeQuery.tabletLandscape} {
    display: block;
    position: sticky;
    top: 2rem;
    max-height: 100vh;
    overflow: scroll;
    margin-bottom: 5rem;
    border: none;
    padding: 0;
    max-width: 100%;
  }
`;
