import { LiveMatchCardStruct } from "@/shared/types/LiveMatchCardType";
import {
  array,
  boolean,
  Infer,
  nullable,
  optional,
  string,
  type,
} from "superstruct";

export const XPALiveMatchesDataStruct = type({
  uiKey: string(),
  upcomingMatches: nullable(array(LiveMatchCardStruct)),
  noMorePages: optional(boolean()),
});

export type XPALiveMatchesDataType = Infer<typeof XPALiveMatchesDataStruct>;
