import { FC } from "react";
import { Wrapper } from "@ui/blocks/Footer/elements/Wrapper";
import {
  ImageWithFallBack,
  ImageWithFallBackProps,
} from "@ui/blocks/ImageWithFallBack";
import { isNullish } from "@/shared/helpers/isNullish";
import { SectionsList } from "@ui/blocks/Footer/elements/SectionsList";
import { SectionsElement } from "@ui/blocks/Footer/elements/SectionsElement";
import { TitleText } from "@ui/elements/TitleText";
import { BodyText } from "@ui/elements/BodyText";
import { NavList } from "@ui/blocks/Footer/elements/NavList";
import { FontSize } from "@themeTypes/FontSizeType";
import { SocialMediaList } from "@ui/blocks/Footer/elements/SocialMediaList";
import { SocialMediaElement } from "@ui/blocks/Footer/elements/SocialMediaElement";
import { SocialMediaLink } from "@ui/blocks/Footer/elements/SocialMediaLink";
import { Limit } from "@ui/blocks/Footer/elements/Limit";
import { HorizontalSeparator } from "@ui/elements/HorizontalLine";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { useLocale } from "@/shared/hooks/useLocale";

interface Section {
  title: string;
  links: Array<{ title: string; url: string }>;
}

interface SocialMedia {
  icon: ImageWithFallBackProps;
  title: string;
  handle: string; // for example @onefootball
  url: string;
}

export interface FooterProps {
  title: string;
  sections: Array<Section>;
  icon?: ImageWithFallBackProps;
  socialMedia?: Array<SocialMedia>;
}

export const Footer: FC<FooterProps> = (props) => {
  const { sections, icon, socialMedia, title } = props;
  const { t } = useLocale();

  return (
    <Wrapper>
      <Limit>
        <TitleText color={ThemeColor.LabelText} size={FontSize.Title_5}>
          {title}
        </TitleText>

        {!isNullish(socialMedia) && (
          <>
            <SocialMediaList>
              {socialMedia.map((element) => {
                return (
                  <SocialMediaElement key={element.title}>
                    <SocialMediaLink href={element.url}>
                      <ImageWithFallBack
                        {...element.icon}
                        width={24}
                        height={24}
                      ></ImageWithFallBack>
                      <BodyText size={FontSize.BodyText_8} isEllipsis>
                        {element.title}
                      </BodyText>
                      <BodyText
                        isEllipsis
                        size={FontSize.BodyText_8}
                        fontWeight={700}
                      >
                        {element.handle}
                      </BodyText>
                    </SocialMediaLink>
                  </SocialMediaElement>
                );
              })}
            </SocialMediaList>
            <HorizontalSeparator></HorizontalSeparator>
          </>
        )}

        {!isNullish(icon) && <ImageWithFallBack {...icon}></ImageWithFallBack>}

        <SectionsList>
          {sections.map((section) => {
            return (
              <SectionsElement key={section.title}>
                <TitleText size={FontSize.Title_7}>
                  {t(section.title).toUpperCase()}
                </TitleText>
                <nav>
                  <NavList>
                    {section.links.map((link) => {
                      return (
                        <li key={link.title}>
                          <BodyText
                            as={"a"}
                            fontWeight={400}
                            size={FontSize.BodyText_8}
                            href={t(link.url)}
                          >
                            {t(link.title)}
                          </BodyText>
                        </li>
                      );
                    })}

                    {section.title === "FOOTER_LEGAL" && (
                      <li>
                        <BodyText
                          id="ot-sdk-btn"
                          className="ot-sdk-show-settings"
                          as={"a"}
                          fontWeight={400}
                          size={FontSize.BodyText_8}
                        >
                          {t("FOOTER_PRIVACY_SETTINGS")}
                        </BodyText>
                      </li>
                    )}
                  </NavList>
                </nav>
              </SectionsElement>
            );
          })}
        </SectionsList>
      </Limit>
    </Wrapper>
  );
};
