import { YouboraConfigType } from "@ui/blocks/VideoPlayer/types/YouboraConfigType";

export function parseYouboraValues(
  youboraConfig: YouboraConfigType,
): Record<string, string | boolean | number> {
  const result: Record<string, string | boolean | number> = {};

  Object.entries(youboraConfig).forEach(([key, value]) => {
    Object.keys(value).forEach((k) => {
      result[key] = youboraConfig[key][k];
    });
  });

  return result;
}
