import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "@/shared/constants/endpoints";

export const webExperienceApi = createApi({
  reducerPath: "webExperienceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    mode: "cors",
    credentials: "include",
  }),
  endpoints: () => ({}),
});
