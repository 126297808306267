import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { isNullish } from "@/shared/helpers/isNullish";

interface TwoColumnGridLayoutProps {
  wide?: boolean;
}

export const TwoColumnGridLayout = styled.section<TwoColumnGridLayoutProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  flex: 1;
  @media all and ${deviceSizeQuery.tabletLandscape} {
    grid-template-columns: ${(p) =>
      !isNullish(p.wide) && p.wide ? "55% 1fr" : "1.5fr 1fr"};
    grid-column-gap: 2rem;
    width: 100%;
    margin: 0 auto;
  }
  @media all and ${deviceSizeQuery.desktop} {
    grid-template-columns: ${(p) =>
      !isNullish(p.wide) && p.wide ? "65% 1fr" : "2fr 1fr"};
  }
`;
