import { SingleFilterWrapper } from "@ui/elements/SingleFilterWrapper";
import React, { FC, useMemo, useState } from "react";
import { LiveCompetitionsListDataType } from "@/shared/types/LiveCompetitionsListDataType";
import { renderCompetitions } from "@ui/blocks/LiveCompetitionsFilter/utils/renderCompetitions";
import { SingleFilterContent } from "@ui/elements/SingleFilterContent";
import { CompetitionsFilterItem } from "@ui/blocks/CompetitionsFilterItem";
import { SingleFilterTitle } from "@ui/blocks/SingleFilterTitle";
import { useLocale } from "@/shared/hooks/useLocale";

export interface LiveCompetitionsFilterProps {
  title: string;
  items: Array<LiveCompetitionsListDataType>;
  selectedItem: string;
  onItemClicked: (itemID: string) => void;
  isDarkTheme: boolean;
}

export const LiveCompetitionsFilter: FC<LiveCompetitionsFilterProps> = (
  props,
) => {
  const { title, items, onItemClicked, isDarkTheme, selectedItem } = props;

  const [collapsed, setCollapsed] = useState(false);
  const { t } = useLocale();
  const competitionItems = useMemo(
    () => renderCompetitions({ items, onItemClicked, selectedItem }),
    [items, onItemClicked, selectedItem],
  );

  return (
    <SingleFilterWrapper collapsed={collapsed}>
      <SingleFilterContent>
        <SingleFilterTitle
          title={title}
          isDarkTheme={isDarkTheme}
          onTitleClick={() => setCollapsed(!collapsed)}
          collapsed={collapsed}
        />
        <CompetitionsFilterItem
          onItemClick={onItemClicked}
          text={t("FILTERS_ALL_COMPETITIONS")}
          isSelected={selectedItem === ""}
        />
        {competitionItems}
      </SingleFilterContent>
    </SingleFilterWrapper>
  );
};
