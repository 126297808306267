export const ThemeColor = {
  ActiveElement: "activeElement",
  Background: "background",
  BackgroundReverse: "backgroundReverse",
  Border: "border",
  BodyText: "bodyText",
  BrandPrimary: "brandPrimary",
  BrandQuaternary: "brandQuaternary",
  BrandQuinary: "brandQuinary",
  BrandSecondary: "brandSecondary",
  BrandTernary: "brandTernary",
  ElevatedBackground: "elevatedBackground",
  Headline: "headline",
  InactiveState: "inactiveState",
  LabelText: "labeltext",
  NavigationBackground: "navigationBackground",
  PitchLineup: "pitchLineup",
  RedCard: "redCard",
  YellowCard: "yellowCard",
} as const;

export type ThemeColorType = (typeof ThemeColor)[keyof typeof ThemeColor];
