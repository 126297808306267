import { Outlet } from "react-router-dom";
import { FC } from "react";

import { RouteContentWrapper } from "@ui/elements/RouteContentWrapper";
import { PageWrapper } from "@ui/elements/PageWrapper";
import { HeaderTop } from "@ui/blocks/HeaderTop";
import { useAppDispatch, useAppSelector } from "@/shared/hooks/appHooks";
import { selectIsAuthenticated } from "@features/authentication";
import {
  selectIsDarkTheme,
  toggleDarkTheme,
} from "@features/pageLayout/store/pageLayoutSlice";
import { ScreenNameType } from "@features/tracking/screen-names";
import { EventName } from "@/modules/tracking/constants/EventName";
import { trackingService } from "@features/tracking/services/trackingService";
import { Footer } from "@ui/blocks/Footer";
import { FooterConfig } from "@features/pageLayout/config/FooterConfig";
import { useLanguageMenu } from "@features/pageLayout/config/useLanguageMenu";
import { FooterWrapper } from "@features/pageLayout/elements/FooterLayout";

export const PageLayoutContainer: FC = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsAuthenticated);
  const isDarkMode = useAppSelector(selectIsDarkTheme);
  const lenguageMenuConfig = useLanguageMenu();

  const sendNavBarInteractionEvent = (screenName: ScreenNameType) => {
    void trackingService.sendEvent({
      name: EventName.NavBarInteraction,
      properties: {
        screen_accessed: screenName,
      },
    });
  };

  const handleThemeChange = () => {
    dispatch(toggleDarkTheme(!isDarkMode));
    localStorage.setItem("isDarkTheme", JSON.stringify(!isDarkMode));
  };

  return (
    <PageWrapper data-testid="page-layout-container">
      <HeaderTop
        onSendTrackingEvent={sendNavBarInteractionEvent}
        imageHeader={{
          src: "/assets/icons/OFTV.svg",
          darkModeSrc: "/assets/icons/OFTVDark.svg",
          alt: "",
          height: 44,
          width: 75,
        }}
        isLoggedIn={isLoggedIn}
        isDarkMode={isDarkMode}
        languageMenu={lenguageMenuConfig}
        onChangeTheme={handleThemeChange}
      />
      <RouteContentWrapper>
        <Outlet />
      </RouteContentWrapper>
      <FooterWrapper>
        <Footer {...FooterConfig}> </Footer>
      </FooterWrapper>
    </PageWrapper>
  );
};
