import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const SocialMediaList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media all and (${deviceSizeQuery.tabletPortrait}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
