import { Wrapper } from "@ui/blocks/PlayerLoader/elements/Wrapper";
import { BodyText } from "@ui/elements/BodyText";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { FontSize } from "@themeTypes/FontSizeType";
import { FC, ReactNode } from "react";

interface PlayerLoaderProps {
  children: ReactNode;
}

interface PlayerLoaderElements {
  Wrapper: FC;
}

const PlayerLoader: FC<PlayerLoaderProps> & PlayerLoaderElements = ({
  children,
}) => {
  return (
    <Wrapper>
      <BodyText color={ThemeColor.BrandQuinary} size={FontSize.BodyText_3}>
        {children}
      </BodyText>
    </Wrapper>
  );
};

PlayerLoader.Wrapper = Wrapper;

export { PlayerLoader };
