import { array, Infer, optional, string, type, unknown } from "superstruct";
import { VideoPlayerDataStruct } from "@/shared/types/VideoPlayerData";
import { RelatedVideosDataStruct } from "@features/matchDetail/types/RelatedVideosDataType";
import { XPATrackingEventStruct } from "@/shared/types/XPATrackingEvent";

export const ClipsDetailDTOStruct = type({
  pageTitle: string(),
  videojsPlayer: VideoPlayerDataStruct,
  publisherImprintLink: optional(
    type({
      text: string(),
      url: string(),
    }),
  ),
  providerLogo: optional(
    type({
      alt: string(),
      path: string(),
    }),
  ),
  providerName: string(),
  providerId: string(),
  relatedVideos: RelatedVideosDataStruct,
  trackingEvents: array(XPATrackingEventStruct),
  googleAdsPlaceholder: unknown(),
  publishTime: optional(string()),
  publishTimestamp: optional(string()),
});

export type ClipsDetailDTO = Infer<typeof ClipsDetailDTOStruct>;
