import { Infer, optional, string, type } from "superstruct";

export const CustomAdsParametersStruct = type({
  sku: optional(string()),
  rightsId: optional(string()),
  providerId: optional(string()),
  contentLength: optional(string()),
  videoLengthBucket: optional(string()),
  contentId: optional(string()),
  contentTags: optional(string()),
});

export type CustomAdsParameters = Infer<typeof CustomAdsParametersStruct>;
