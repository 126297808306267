import { setCurrentScreen } from "@features/tracking/store/screenNameSlice";
import { ScreenNameType } from "@features/tracking/screen-names";
import { useAppDispatch } from "@/shared/hooks/appHooks";
import { useEffect } from "react";

export const useScreenName = (screenName: ScreenNameType): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(screenName));
  }, [dispatch, screenName]);
};
