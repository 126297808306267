import React, { FC } from "react";
import { CardSectionTitle } from "@ui/blocks/CardSectionTitle";
import { VODSection } from "@features/discovery/blocks/VODSection";
import { DiscoveryGridItemWrapper } from "@features/discovery/elements/DiscoveryGridItemWrapper";
import { useGetNewsRailQuery } from "@features/discovery/services/discoveryApi";
import { useLocale } from "@/shared/hooks/useLocale";
import { ColumnGrid } from "@features/discovery/elements/ColumnGrid";
import { VODSectionLoader } from "@features/discovery/blocks/VODSectionLoader";
import { AppRoutes } from "@/shared/constants/Routes";
import {
  GalleryNames,
  viewMoreTrackingEvent,
} from "@ui/blocks/CardSectionTitle/ViewMoreEvent";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { trackingService } from "@features/tracking/services/trackingService";

export const VideoNewsSectionContainer: FC = () => {
  const { t, locale } = useLocale();
  const { data, isFetching, isSuccess } = useGetNewsRailQuery({
    locale,
  });
  return (
    <DiscoveryGridItemWrapper>
      <CardSectionTitle
        linkTo={AppRoutes.Clips}
        linkText={t("VIEW_MORE")}
        onClick={() =>
          trackingService.sendEvent(
            viewMoreTrackingEvent(GalleryNames.VideoNews),
          )
        }
      >
        {t("VIDEO_NEWS")}
      </CardSectionTitle>
      <ColumnGrid minItemWidth="18.125rem">
        {isFetching ? (
          <VODSectionLoader
            numberOfItems={8}
            cardsTestID="video-news-cards-loader"
          />
        ) : (
          isSuccess && (
            <VODSection
              items={data.videoGallery.items}
              numberOfSectionItems={8}
              sectionType={SectionTypes.news}
            />
          )
        )}
      </ColumnGrid>
    </DiscoveryGridItemWrapper>
  );
};
