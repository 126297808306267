import { FC } from "react";
import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

const BurgerSvg = styled.svg<WithThemeType>`
  color: ${(p) => p.theme.colors.headline};
  width: 24px;
  height: 24px;
`;

export const MenuOpen: FC = () => {
  return (
    <BurgerSvg>
      <path
        d="M22 5.39502L2 5.39502"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22 12.3948L2 12.3948"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22 19.3948L2 19.3948"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </BurgerSvg>
  );
};
