import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GEOLOCATION_URL } from "@/shared/constants/endpoints";
import { create, defaulted, string } from "superstruct";

export const responseStruct = defaulted(string(), "unknown");

export const geolocationApi = createApi({
  reducerPath: "geolocationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GEOLOCATION_URL,
    responseHandler: "text",
  }),
  endpoints: (builder) => ({
    getLocation: builder.query<string, void>({
      query: () => "",
      transformResponse: (response: string) => {
        return create(response, responseStruct);
      },
    }),
  }),
});

export const { useGetLocationQuery } = geolocationApi;
