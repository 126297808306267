import { avoInspector } from "@/modules/tracking/avo-inspector";
import { rudderstack as analytics } from "./analytics";
import { Tracker } from "@features/tracking/services/trackingService";

export const rudderstack: Tracker = {
  init: () => {
    analytics.init();
  },

  sendEvent(event): void {
    avoInspector.trackSchemaFromEvent(event.name, event.properties);
    analytics.track(event.name, event.properties);
  },

  setUserId(userId): void {
    analytics.identify(userId);
  },

  resetUserId(): void {
    analytics.reset();
  },
} as const;
