import { Infer, number, optional, string, type } from "superstruct";

export const AdTrackingParametersStruct = type({
  screen: optional(string()),
  playlistPosition: optional(number()),
  contentType: optional(string()),
  videoProviderId: optional(number()),
  videoProviderName: optional(string()),
  rightsId: optional(number()),
  competitionId: optional(number()),
  matchId: optional(number()),
  videoLengthInSec: optional(number()),
  matchState: optional(string()),
  matchMinute: optional(string()),
  language: optional(string()),
});

export type AdTrackingParameters = Infer<typeof AdTrackingParametersStruct>;
