import styled from "styled-components";
import { TitleText } from "@ui/elements/TitleText";
import { lineClamp } from "@ui/elements/GlobalStyles/helpers/lineClamp";

const MAX_NUMBER_OF_LINES = 3;

export const CardContentTitle = styled(TitleText)`
  white-space: break-spaces;
  ${lineClamp(MAX_NUMBER_OF_LINES)};
`;
