import { handleError } from "../services/handleError";
import { ErrorBoundary } from "react-error-boundary";
import React, { FC, ReactNode } from "react";
import { ErrorFallback } from "@features/errorFallback";

interface ErrorBoundaryContainerProps {
  children: ReactNode;
}

export const ErrorBoundaryContainer: FC<ErrorBoundaryContainerProps> = ({
  children,
}) => {
  return (
    <ErrorBoundary fallbackRender={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};
