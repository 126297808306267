import { rudderstack } from "@/modules/tracking/rudderstack-service";
import { getTrackingEventSystemProperties } from "@features/tracking/services/getSystemProperties";

export type PropertiesTypes = string | number | boolean | undefined;
export interface Event {
  name: string;
  properties: Record<string, PropertiesTypes>;
}

export interface Tracker {
  init: () => void;
  sendEvent: (event: Event) => void;
  setUserId: (id: string) => void;
  resetUserId: () => void;
}

const supportedTrackers: Array<Tracker> = [rudderstack];

export const trackingService = {
  sendEvent: async (event: Event): Promise<void> => {
    const { name, properties } = event;
    const systemProperties = await getTrackingEventSystemProperties();

    supportedTrackers.forEach((tracker) => {
      tracker.sendEvent({
        name,
        properties: { ...systemProperties, ...properties },
      });
    });
  },

  setUserId: (userId: string): void => {
    supportedTrackers.forEach((tracker) => {
      tracker.setUserId(userId);
    });
  },

  resetUserId: (): void => {
    supportedTrackers.forEach((tracker) => {
      tracker.resetUserId();
    });
  },
};
