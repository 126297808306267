import { FC } from "react";
import { useParams } from "react-router";
import { useGetCreatorDetailQuery } from "@features/creatorDetail/services/creatorDetailApi";
import { isNullish } from "@/shared/helpers/isNullish";
import { CardPartnerNameWrapper } from "@/shared/ui/elements/CardPartnerNameWrapper";
import { ImageWithFallBack } from "@/shared/ui/blocks/ImageWithFallBack";
import { BannerWrapper } from "@/features/creatorDetail/elements/BannerWrapper";
import { CardCreatorProfile } from "../elements/CardCreatorProfile";
import { CreatorProfileTitle } from "../elements/CreatorProfileTitle";
import { DEFAULT_BANNER_IMAGE } from "../constants/creatorDetailConstants";

export const CreatorHeaderContainer: FC = () => {
  const params = useParams();
  const { data, isLoading } = useGetCreatorDetailQuery({
    providerId: params.id ?? "1",
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isNullish(data)) {
    return null;
  }

  return (
    <>
      <BannerWrapper>
        <ImageWithFallBack
          height={200}
          src={data.banner_url === "" ? DEFAULT_BANNER_IMAGE : data.banner_url}
          alt={data.display_name}
        />
      </BannerWrapper>
      <CardPartnerNameWrapper>
        <ImageWithFallBack
          width={150}
          height={150}
          src={data.logo}
          alt={data.display_name}
        />
        <CardCreatorProfile>
          <CreatorProfileTitle>{data.display_name}</CreatorProfileTitle>
          <p>{data.bio}</p>
          <p>
            <a href="https://facebook.com/newcastleunited" target="_blank">
              {data.social_link_url}
            </a>
          </p>
        </CardCreatorProfile>
      </CardPartnerNameWrapper>
    </>
  );
};
