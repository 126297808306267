import * as Inspector from "avo-inspector";
const AVO_API_KEY = "rwpHzE9l5SwgkJqP6piv";

export const avoInspector = new Inspector.AvoInspector({
  apiKey: AVO_API_KEY,
  env: Inspector.AvoInspectorEnv.Prod,
  version: "1.0.0",
  appName: "of-web-tvhub",
});

avoInspector.enableLogging(false);
