export const checkForAdBlocker = async (
  baitScriptUrl: string,
): Promise<void> => {
  try {
    await fetch(baitScriptUrl, {
      method: "HEAD",
      cache: "no-store",
      mode: "no-cors",
    });
  } catch (error) {
    throw new Error("AD BLOCKER ACTIVE");
  }
};

// If ad blocker is active, importing this plugin will throw runtime error
// we need to check for ad blocker before importing this plugin
export const importAdPlugin = async (): Promise<() => void> => {
  const plugin = await import("videojs-contrib-ads");
  return plugin.default;
};
