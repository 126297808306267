import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const DurationWrapper = styled.div<WithThemeType>`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${(p) => p.theme.colors.backgroundReverse};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.25rem 0.5rem;
  border-bottom-right-radius: ${(p) => p.theme.roundedCorners.big};
  border-top-left-radius: ${(p) => p.theme.roundedCorners.big};
`;
