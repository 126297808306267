import styled, { css } from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { hex2rgb } from "@/shared/helpers/hex2rgb";

interface SingleFilterWrapperProps extends WithThemeType {
  collapsed: boolean;
}

export const SingleFilterWrapper = styled.div<SingleFilterWrapperProps>`
  margin-top: 2rem;
  overflow: hidden;
  padding: 1rem 1rem 0 1rem;
  background-color: ${({ theme }) => theme.colors.navigationBackground};
  position: relative;
  max-height: ${({ collapsed }) => (collapsed ? "3.4rem" : "30rem")};
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    transition: all 0.3s ease;
    ${({ theme, collapsed }) => {
      const { r, g, b } = hex2rgb(theme.colors.navigationBackground);
      return css`
        background: linear-gradient(
          0deg,
          rgba(${r}, ${g}, ${b}, 1) 0%,
          rgba(${r}, ${g}, ${b}, 0.8) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: ${collapsed ? 0 : 1};
        visibility: ${collapsed ? "hidden" : "visible"};
      `;
    }};
  }
`;
