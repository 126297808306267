export const VideoPlayerEvent = {
  Ready: "ready",
  Play: "play",
  Pause: "pause",
  Ended: "ended",
  Error: "error",
  Playlistitem: "playlistitem", //from videojs-playlist sdk
} as const;

export type VideoPlayerEventType =
  (typeof VideoPlayerEvent)[keyof typeof VideoPlayerEvent];
