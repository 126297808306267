import { BurgerButton } from "@ui/elements/BurgerButton";
import { FC } from "react";
import { AbsolutePositionsType } from "@/shared/types/AbsolutePositionsType";
import { ButtonVariant } from "@ui/elements/Button";

interface BurgerMenuProps {
  onBurgerClick: () => void;
  iconSrc: string;
  iconAlt: string;
  absolutePosition?: Record<AbsolutePositionsType, string>;
}

export const BurgerMenu: FC<BurgerMenuProps> = ({
  onBurgerClick,
  absolutePosition,
  iconSrc,
  iconAlt,
}) => {
  return (
    <BurgerButton
      variant={ButtonVariant.iconOnly}
      onClick={onBurgerClick}
      position={absolutePosition}
      data-testid="burger-menu-button"
    >
      <img src={iconSrc} alt={iconAlt} />
    </BurgerButton>
  );
};
