import styled from "styled-components";
import { ZIndexes } from "@/shared/constants/zIndexes";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { limit } from "@ui/elements/GlobalStyles/helpers/limit";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const HeaderList = styled.ul<WithThemeType>`
  ${limit};
  width: 100%;
  height: 3.4375rem;
  z-index: ${ZIndexes.Above};
  display: flex;
  gap: 2.5rem;
  align-items: center;

  @media all and (${deviceSizeQuery.tabletPortrait}) {
    height: 5rem;
  }
`;
