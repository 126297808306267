import { Infer, enums, type, string } from "superstruct";

export const ClientParameter = {
  PARAMETER_UNSPECIFIED: "PARAMETER_UNSPECIFIED",
  PARAMETER_VISIBLE_DURATION_SECONDS: "PARAMETER_VISIBLE_DURATION_SECONDS",
  PARAMETER_PREVIOUS_SCREEN: "PARAMETER_PREVIOUS_SCREEN",
  PARAMETER_OPENING_SOURCE: "PARAMETER_OPENING_SOURCE",
  PARAMETER_DISPLAY_MODE: "PARAMETER_DISPLAY_MODE",
  PARAMETER_SCROLL_TO_BOTTOM: "PARAMETER_SCROLL_TO_BOTTOM",
  PARAMETER_VIDEO_FINISHED: "PARAMETER_VIDEO_FINISHED",
  PARAMETER_VIDEO_PLAYBACK_DURATION_SECONDS:
    "PARAMETER_VIDEO_PLAYBACK_DURATION_SECONDS",
  PARAMETER_DATE_CHOSEN: "PARAMETER_DATE_CHOSEN",
  PARAMETER_MATCH_EVENTS_TOGGLED: "PARAMETER_MATCH_EVENTS_TOGGLED",
  PARAMETER_MATCH_LINEUP_SIDE_TOGGLED: "PARAMETER_MATCH_LINEUP_SIDE_TOGGLED",
  PARAMETER_MATCH_PREDICTION_USER_VOTED:
    "PARAMETER_MATCH_PREDICTION_USER_VOTED",
  PARAMETER_VIDEO_ENTERED_FULLSCREEN_MODE:
    "PARAMETER_VIDEO_ENTERED_FULLSCREEN_MODE",
  PARAMETER_SCROLL_TO_END_OF_SECTION: "PARAMETER_SCROLL_TO_END_OF_SECTION",
  UNRECOGNIZED: "UNRECOGNIZED",
} as const;

export const ClientParameterStruct = enums(Object.keys(ClientParameter));
export const ClientParameterRequestStruct = type({
  key: string(),
  type: ClientParameterStruct,
});

export type ClientParameterType = Infer<typeof ClientParameterStruct>;
export type ClientParameterRequest = Infer<typeof ClientParameterRequestStruct>;
