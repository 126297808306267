import {
  LiveCompetitionsListDataType,
  LiveCompetitionsListDataTypeStruct,
} from "@/shared/types/LiveCompetitionsListDataType";
import { array, create } from "superstruct";
import { webExperienceApi } from "@/shared/services/webExperienceApi";
import { filterCompetitionsWithoutMatches } from "@features/liveMatches/helpers/filterCompetitionsWithoutMatches";
import { sortCompetitionsAlphabetically } from "@features/liveMatches/helpers/sortCompetitionsAlphabetically";

export const liveMatchesApi = webExperienceApi.injectEndpoints({
  endpoints: (builder) => ({
    getLiveCompetitionsList: builder.query<
      Array<LiveCompetitionsListDataType>,
      { locale: string }
    >({
      query: ({ locale }) => `${locale}/tv-hub/matches/competitions-rights`,
      transformResponse: (baseQueryReturnValue) => {
        const competitions = create(
          baseQueryReturnValue,
          array(LiveCompetitionsListDataTypeStruct),
          "can not create",
        );
        return sortCompetitionsAlphabetically(
          filterCompetitionsWithoutMatches(competitions),
        );
      },
    }),
  }),
});

export const {
  useGetLiveCompetitionsListQuery,
  useLazyGetLiveCompetitionsListQuery,
} = liveMatchesApi;
