import { useAppSelector } from "@/shared/hooks/appHooks";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";
import { useState } from "react";
import { isNullish } from "@/shared/helpers/isNullish";

interface VideoDetailSettingsType {
  isDarkTheme: boolean;
  isWidgetsVisible: boolean;
  onToggleWidgetsVisible: (arg?: boolean) => void;
}

export const useVideoDetailSettings = (): VideoDetailSettingsType => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const [isWidgetsVisible, setWidgetsVisible] = useState(false);

  const onToggleWidgetsVisible = (toggle?: boolean) => {
    isNullish(toggle)
      ? setWidgetsVisible(!isWidgetsVisible)
      : setWidgetsVisible(toggle);
  };

  return {
    isDarkTheme,
    isWidgetsVisible,
    onToggleWidgetsVisible,
  };
};
