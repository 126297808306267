import { enums, Infer, object, record, string } from "superstruct";

export const ServerParameter = {
  UNKNOWN: "UNKNOWN",
  FLOAT: "FLOAT",
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
  INTEGER: "INTEGER",
  UNRECOGNIZED: "UNRECOGNIZED",
};

export const ServerParameterStruct = enums(Object.values(ServerParameter));

export const TypedServerParameterStruct = record(
  string(),
  object({
    value: string(),
    type: ServerParameterStruct,
  }),
);

export type ServerParameterType = Infer<typeof ServerParameterStruct>;
export type TypedServerParameter = Infer<typeof TypedServerParameterStruct>;
