import { AppRoutes } from "@/shared/constants/Routes";
import { ScreenName } from "@features/tracking/screen-names";

export const HeaderLinks = [
  {
    to: AppRoutes.LiveMatches,
    screenName: ScreenName.LiveMatches,
    textKey: "NAVIGATION_LIVE",
  },
  {
    to: AppRoutes.Highlights,
    screenName: ScreenName.Highlights,
    textKey: "NAVIGATION_HIGHLIGHTS",
  },
  {
    to: AppRoutes.Clips,
    screenName: ScreenName.Clips,
    textKey: "NAVIGATION_VIDEOS",
  },
];
