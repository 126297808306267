import { LiveCompetitionsListDataType } from "@/shared/types/LiveCompetitionsListDataType";

export const sortCompetitionsAlphabetically = (
  competitions: Array<LiveCompetitionsListDataType>,
): Array<LiveCompetitionsListDataType> => {
  return competitions.sort((a, b) => {
    if (a.competition_name > b.competition_name) {
      return 1;
    }

    if (b.competition_name > a.competition_name) {
      return -1;
    }

    return 0;
  });
};
