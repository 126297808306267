import styled from "styled-components";
import { Button } from "@ui/elements/Button";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const ProfileButton = styled(Button)`
  display: none;
  @media all and (${deviceSizeQuery.tabletPortrait}) {
    display: grid;
  }
`;
