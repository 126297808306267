import "react-loading-skeleton/dist/skeleton.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./shared/store";
import { App } from "./App";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { initTranslations } from "@/i18n";

initTranslations();

const createRootElement = (): HTMLDivElement => {
  const root = document.createElement("div");
  root.setAttribute("id", "root");
  document.body.appendChild(root);
  return root;
};

const rootElement = document.getElementById("root") ?? createRootElement();

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes>
          <App />
        </StyleSheetManager>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
