import React, { FC } from "react";
import { CardSectionTitle } from "@ui/blocks/CardSectionTitle";
import { AppRoutes } from "@/shared/constants/Routes";
import { LiveMatchesSection } from "@features/discovery/helpers/LiveMatchesSection";
import { useGetLiveMatchesRailQuery } from "@features/discovery/services/discoveryApi";
import { DiscoveryGridItemWrapper } from "@features/discovery/elements/DiscoveryGridItemWrapper";
import { useLocale } from "@/shared/hooks/useLocale";
import { ColumnGrid } from "@features/discovery/elements/ColumnGrid";
import { useAppSelector } from "@/shared/hooks/appHooks";
import {
  selectCurrentPage,
  selectFilteredCompetition,
  selectFilteredPrice,
} from "@features/liveMatches/store/liveMatchesSlice";
import { NUMBER_OF_ITEMS_PER_PAGE } from "@features/liveMatches/constants/LiveMatchesConstants";
import { renderLiveMatchesLoading } from "@features/discovery/helpers/renderLiveMatchesLoading";
import {
  GalleryNames,
  viewMoreTrackingEvent,
} from "@ui/blocks/CardSectionTitle/ViewMoreEvent";
import { isNullish } from "@/shared/helpers/isNullish";
import { trackingService } from "@features/tracking/services/trackingService";

export const LiveMatchesSectionContainer: FC = () => {
  const { t, locale } = useLocale();
  const currentPage = useAppSelector(selectCurrentPage);
  const filteredCompetition = useAppSelector(selectFilteredCompetition);
  const filteredPrice = useAppSelector(selectFilteredPrice);
  const { data, isFetching, isSuccess } = useGetLiveMatchesRailQuery({
    locale,
    offset: currentPage * NUMBER_OF_ITEMS_PER_PAGE,
    competitionID: filteredCompetition,
    price: filteredPrice,
  });

  return (
    <DiscoveryGridItemWrapper>
      <CardSectionTitle
        linkTo={AppRoutes.LiveMatches}
        linkText={t("VIEW_MORE")}
        onClick={() =>
          void trackingService.sendEvent(
            viewMoreTrackingEvent(GalleryNames.LiveMatches),
          )
        }
      >
        {t("LIVE_MATCHES")}
      </CardSectionTitle>
      <ColumnGrid minItemWidth="18.125rem">
        {isFetching
          ? renderLiveMatchesLoading(8)
          : isSuccess &&
            !isNullish(data.upcomingMatches) && (
              <LiveMatchesSection
                matchCards={data.upcomingMatches}
                numberOfSectionItems={8}
              />
            )}
      </ColumnGrid>
    </DiscoveryGridItemWrapper>
  );
};
