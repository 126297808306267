import { Infer, number, optional, string, type } from "superstruct";

export const ComscoreMetadataStruct = type({
  contentId: string(),
  publisherName: string(),
  programTitle: string(),
  videoLengthInSec: optional(number()),
});

export type ComscoreMetadata = Infer<typeof ComscoreMetadataStruct>;
