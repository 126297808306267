import React, { FC, useEffect } from "react";
import { TemporaryWrapper } from "@ui/elements/TemporaryWrapper";
import { ScreenName } from "@features/tracking/screen-names";
import { useAppDispatch } from "@/shared/hooks/appHooks";
import { setCurrentScreen } from "@features/tracking/store/screenNameSlice";

const News: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(ScreenName.News));
  }, [dispatch]);

  return <TemporaryWrapper>News UI goes here</TemporaryWrapper>;
};

export { News };
