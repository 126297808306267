import { HeaderLink } from "@ui/blocks/HeaderTop/elements/HeaderLink";
import { Link } from "react-router-dom";
import { ArrowNav } from "@ui/blocks/HeaderTop/elements/ArrowNav";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { FC } from "react";
import { ArrowRightConst } from "@ui/blocks/HeaderTop/constants/ArrowRight";

interface HeaderNavItemProps {
  to: string;
  isActive: boolean;
  onNavItemClick: () => void;
  isDarkMode: boolean;
  text: string;
}

export const HeaderNavItem: FC<HeaderNavItemProps> = ({
  to,
  isActive,
  onNavItemClick,
  isDarkMode,
  text,
}) => {
  return (
    <li>
      <HeaderLink as={Link} to={to} active={isActive} onClick={onNavItemClick}>
        {text}
        <ArrowNav>
          <ImageWithFallBack {...ArrowRightConst} isDarkMode={isDarkMode} />
        </ArrowNav>
      </HeaderLink>
    </li>
  );
};
