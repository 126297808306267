import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/shared/store";

export interface VideoPlayerState {
  isAdBlockerActive: boolean;
}

const initialState: VideoPlayerState = {
  isAdBlockerActive: false,
};

export const videoPlayerSlice = createSlice({
  name: "videoPlayer",
  initialState,
  reducers: {
    toggleAdBlockerActive: (state, action: PayloadAction<boolean>) => {
      state.isAdBlockerActive = action.payload;
    },
  },
});

export const { toggleAdBlockerActive } = videoPlayerSlice.actions;

export const selectAdBlockerActive = (state: RootState): boolean =>
  state.videoPlayer.isAdBlockerActive;

export const videoPlayerReducer = videoPlayerSlice.reducer;
