import { isNullish } from "@/shared/helpers/isNullish";
import { VODdataType } from "@/shared/types/VODdataType";

export const highlightsInfiniteScrollMerger = (
  currentCacheData: VODdataType,
  newData: VODdataType,
): VODdataType => {
  if (isNullish(newData.videoGallery.items)) {
    return { ...currentCacheData, noMorePages: true };
  }

  if (isNullish(currentCacheData)) {
    return { ...newData };
  }

  const cachedItems = currentCacheData.videoGallery.items ?? [];
  return {
    ...currentCacheData,
    videoGallery: {
      ...currentCacheData.videoGallery,
      items: [...cachedItems, ...newData.videoGallery.items],
    },
  };
};
