import { FontSize, FontSizeType } from "@themeTypes/FontSizeType";
import { FC } from "react";
import { TitleText } from "@ui/elements/TitleText";

export interface VideoPlayerTitleProps {
  videoTitle: string;
  titleSize?: FontSizeType;
  titleFontFamily?: string;
}

export const VideoPlayerTitle: FC<VideoPlayerTitleProps> = (props) => {
  const {
    videoTitle,
    titleSize = FontSize.Title_4,
    titleFontFamily = "Druk",
  } = props;

  return (
    <div>
      <TitleText fontFamily={titleFontFamily} size={titleSize}>
        {videoTitle}
      </TitleText>
    </div>
  );
};
