import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

export const SliderIcon = styled.img<WithThemeType>`
  position: absolute;
  height: 1.625rem;
  width: 1.625rem;
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: ${(p) => p.theme.colors.background};
  transition: 0.3s ease;
  border-radius: 50%;
  padding: 0.1875rem;
`;
