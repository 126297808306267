import {
  ClipsDetailDTO,
  ClipsDetailDTOStruct,
} from "@features/clipsDetail/types/ClipsDetailDTO";
import { webExperienceApi } from "@/shared/services/webExperienceApi";
import { create } from "superstruct";

interface GetMatchDetailApiProps {
  locale: string;
  clipID: string;
}

export const clipsDetailApi = webExperienceApi.injectEndpoints({
  endpoints: (builder) => ({
    getClipsDetail: builder.query<ClipsDetailDTO, GetMatchDetailApiProps>({
      query: ({ locale, clipID }) => ({
        url: `${locale}/tv-hub/videos/${clipID}`,
      }),
      transformResponse: (response) => {
        return create(response, ClipsDetailDTOStruct, "can not create");
      },
    }),
  }),
});

export const { useGetClipsDetailQuery } = clipsDetailApi;
