import { css } from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { defaultTheme } from "@/shared/themes";

/*
 * Function that adds white space to the left and right of the viewport.
 */
export const limit = css`
  max-width: calc((100vw - 2 * 1.25rem));
  margin-left: auto;
  margin-right: auto;

  @media all and (${deviceSizeQuery.tabletPortrait}) ) {
    max-width: calc((100vw - 2 * 2.5rem));
  }

  // Apply container if the screen width is 40% larger that smallest desktop
  @media all and (min-width: calc(${defaultTheme.breakpoints
      .tabletLandscape} + 2.5rem * 2)) {
    max-width: calc(
      (100% - (95% - ${defaultTheme.breakpoints.tabletLandscape}))
    );
  }
`;
