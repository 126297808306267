import { useEffect, useState } from "react";
import { NullishType } from "@/shared/types/NullishType";
import { isNullish } from "@/shared/helpers/isNullish";

interface UseImageWithFallbackProps {
  src: string | undefined;
  isDarkMode: boolean;
  darkModeSrc?: string;
  fallBackSrc?: string;
  fallbackDarkSrc?: string;
}

interface UseImageWithFallbackReturn {
  imageSrc: string | null;
  handleError: () => void;
}

const chooseImageToUse = (
  src: string | undefined,
  darkSrc: string | undefined,
  isDarkMode: boolean,
): NullishType<string> => {
  const isSrc = src !== "" && src !== undefined;
  if (!isSrc) {
    return null;
  }
  return isDarkMode ? darkSrc : src;
};

export const useImageWithFallback = (
  props: UseImageWithFallbackProps,
): UseImageWithFallbackReturn => {
  const { src, darkModeSrc, isDarkMode, fallBackSrc, fallbackDarkSrc } = props;
  const [imageSrc, setImageSrc] = useState<NullishType<string>>(null);

  const handleError = () => {
    setImageSrc(chooseImageToUse(fallBackSrc, fallbackDarkSrc, isDarkMode));
  };

  /**
   * The mode may change without the component being dismounted, so we need to use useEffect to change the state
   */
  useEffect(() => {
    setImageSrc(chooseImageToUse(src, darkModeSrc, isDarkMode));
  }, [darkModeSrc, isDarkMode, src]);

  if (isNullish(imageSrc)) {
    return {
      imageSrc: null,
      handleError,
    };
  }

  return {
    imageSrc,
    handleError,
  };
};
