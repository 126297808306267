import { VideoCardData } from "@/shared/types/VideoCardType";
import { FC } from "react";
import { VideoCard } from "@ui/blocks/VideoCard";
import { getCardUrl } from "@features/discovery/helpers/getCardUrl";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { SectionCardWrapper } from "@features/discovery/elements/SectionCardWrapper";
import { EventName } from "@/modules/tracking/constants/EventName";
import { trackingService } from "@features/tracking/services/trackingService";

interface VODSectionProps {
  numberOfSectionItems: number;
  sectionType?: keyof typeof SectionTypes;
  items?: Array<VideoCardData>;
}

export const VODSection: FC<VODSectionProps> = ({
  items,
  numberOfSectionItems,
  sectionType = SectionTypes.unknown,
}) => {
  if (!items) {
    return [];
  }
  return items.map((item, i) => {
    if (i > numberOfSectionItems - 1) {
      return null;
    } else {
      return (
        <SectionCardWrapper key={item.uiKey}>
          <VideoCard
            imageObject={item.imageObject}
            providerName={item.providerName}
            providerLogo={item.providerLogo}
            publishTime={item.publishTime}
            title={item.title}
            url={getCardUrl(item)}
            duration={item.duration}
            onClick={() => {
              void trackingService.sendEvent({
                name: EventName.CardClicked,
                properties: {
                  card_type: sectionType,
                  item_id: item.id,
                },
              });
            }}
          />
        </SectionCardWrapper>
      );
    }
  });
};
