import { store } from "@/shared/store";
import i18n from "i18next";
import { is, string } from "superstruct";
import { ScreenNameType } from "@features/tracking/screen-names";

export interface SystemProperties {
  geo_country: string;
  screen: ScreenNameType;
  previous_screen: ScreenNameType;
  app_language: string;
  logged_in: boolean;
  darkmode_enabled: boolean;
  platform: "of-web-tvhub";
}

const getLocationFromStore = () => {
  const state = store.getState();
  const geolocationState =
    state.geolocationApi.queries["getLocation(undefined)"];
  return geolocationState?.data;
};

// Limit store subscription monitoring
// If no store changes for 3s, return what is currently cached
const PromiseLimiter = new Promise<string>((res, rej) => {
  setTimeout(() => {
    // Set timeout to resolve if no store changes occur
    // i.e. when video player plays we want to send event for ads, but no store changes triggered
    const geoData = getLocationFromStore();
    if (is(geoData, string())) {
      res(geoData); // Resolve the promise with the geolocation data
    }
  }, 3000);
});

const StoreSubscriptionPromise = new Promise<string>((resolve) => {
  const unsubscribe = store.subscribe(() => {
    const geoData = getLocationFromStore();

    if (is(geoData, string())) {
      unsubscribe(); // Unsubscribe to avoid memory leaks
      resolve(geoData); // Resolve the promise with the geolocation data
    }
  });
});

export const getGeolocation = async (): Promise<string> => {
  return Promise.race([PromiseLimiter, StoreSubscriptionPromise]);
};

export const getTrackingEventSystemProperties =
  async (): Promise<SystemProperties> => {
    const state = store.getState();

    const isDarkMode = state.pageLayout.isDarkTheme;
    const isAuthenticated = state.authentication.isAuthenticated;
    const currentScreen = state.screenName.current_screen;
    const previousScreen = state.screenName.previous_screen;
    const locale = i18n.language;
    let geo_country = "unknown";

    try {
      geo_country = await getGeolocation();
    } catch (e) {
      // fails in silence and sadness
    }

    return {
      geo_country,
      screen: currentScreen,
      previous_screen: previousScreen,
      app_language: locale,
      logged_in: isAuthenticated,
      darkmode_enabled: isDarkMode,
      platform: "of-web-tvhub",
    };
  };
