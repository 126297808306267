import { Infer, optional, string, type } from "superstruct";

export const DrmPropertiesStruct = type({
  token: string(),
  fpCertificateUrl: optional(string()),
  processSpcUrl: optional(string()),
  widevineUrl: optional(string()),
  playreadyUrl: optional(string()),
});

export type DrmProperties = Infer<typeof DrmPropertiesStruct>;
