import { coerce, Infer, number, optional, string, type } from "superstruct";

export const VODSearchHitTypeStruct = type({
  provider: type({
    profileImage: string(),
    name: string(),
  }),
  duration: coerce(string(), number(), (value) => {
    return new Date(value * 1000)
      .toISOString()
      .substring(value < 3600 ? 14 : 11, 19);
  }),
  imageUrl: string(),
  title: string(),
  objectID: string(),
  publishedSinceDatetime: optional(string()),
});

export type VODSearchHitType = Infer<typeof VODSearchHitTypeStruct>;
