import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { VideoPlayerEvent } from "@ui/blocks/VideoPlayer/types/VideoPlayerEventType";
import { VideoEvents } from "@ui/blocks/VideoPlayer/types/VideoJSPlayerProps";

export const attachPlayerEvents = (
  player: VideoJSPlayer,
  events?: VideoEvents,
): void => {
  player.on(VideoPlayerEvent.Play, () => {
    events?.onPlay?.();
  });

  player.on(VideoPlayerEvent.Error, () => {
    events?.onError?.();
  });

  player.on(VideoPlayerEvent.Ended, () => {
    events?.onEnd?.();
  });

  player.on(VideoPlayerEvent.Pause, () => {
    events?.onPause?.();
  });

  player.on(VideoPlayerEvent.Playlistitem, () => {
    events?.onPlaylistNewItem?.(player);
  });

  player.on(VideoPlayerEvent.Ready, () => {
    events?.onReady?.();
  });
};
