import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  place-content: center;
  position: relative;
`;
