import { enums, Infer, optional, record, string, type } from "superstruct";

export const TrackerKind = {
  TRACKER_UNSPECIFIED: "TRACKER_UNSPECIFIED",
  TRACKER_FIREBASE: "TRACKER_FIREBASE",
  TRACKER_LOCALYTICS: "TRACKER_LOCALYTICS",
  TRACKER_ADJUST: "TRACKER_ADJUST",
  TRACKER_AIRSHIP: "TRACKER_AIRSHIP",
  TRACKER_SNOWPLOW: "TRACKER_SNOWPLOW",
  TRACKER_GOOGLE: "TRACKER_GOOGLE",
  TRACKER_FACEBOOK: "TRACKER_FACEBOOK",
  TRACKER_RUDDERSTACK: "TRACKER_RUDDERSTACK",
  UNRECOGNIZED: "UNRECOGNIZED",
} as const;

export const TrackerKindStruct = enums(Object.keys(TrackerKind));
export const TrackerStruct = type({
  type: TrackerKindStruct,
  trackerParameters: optional(record(string(), string())),
});

export type TrackerKindType = Infer<typeof TrackerKindStruct>;
export type Tracker = Infer<typeof TrackerStruct>;
