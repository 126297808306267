import styled from "styled-components";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const FeaturedImage = styled(ImageWithFallBack)`
  object-fit: cover;
  object-position: center;
  position: absolute;
  min-height: 70vh;
  @media all and ${deviceSizeQuery.tabletPortrait} {
    min-height: 60vh;
  }
`;
