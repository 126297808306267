import { isNullish } from "@/shared/helpers/isNullish";
import { XPALiveMatchesDataType } from "@/shared/types/LiveMatchesDataType";

export const liveMatchesInfiniteScrollMerger = (
  currentCacheData: XPALiveMatchesDataType,
  newData: XPALiveMatchesDataType,
): XPALiveMatchesDataType => {
  if (isNullish(newData.upcomingMatches)) {
    return { ...currentCacheData, noMorePages: true };
  }

  if (isNullish(currentCacheData.upcomingMatches)) {
    return {
      ...currentCacheData,
      upcomingMatches: [...newData.upcomingMatches],
    };
  }

  const cachedItems = currentCacheData.upcomingMatches;
  return {
    ...currentCacheData,
    upcomingMatches: [...cachedItems, ...newData.upcomingMatches],
  };
};
