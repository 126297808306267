import { VideoCardLoader } from "@ui/blocks/VideoCard/VideoCardLoader";
import { FC } from "react";
import { SectionCardWrapper } from "@features/discovery/elements/SectionCardWrapper";

interface VODSectionLoaderProps {
  numberOfItems: number;
  cardsTestID?: string;
}

export const VODSectionLoader: FC<VODSectionLoaderProps> = ({
  numberOfItems,
  cardsTestID,
}) => {
  return Array.from({ length: numberOfItems }).map((_, i) => {
    return (
      <SectionCardWrapper key={i}>
        <VideoCardLoader testID={cardsTestID} />
      </SectionCardWrapper>
    );
  });
};
