import { CustomAdsParametersStruct } from "./CustomAdsParameters";
import { AdTrackingParametersStruct } from "./AdTrackingParameters";
import { XPATrackingEventStruct } from "@/shared/types/XPATrackingEvent";
import { array, Infer, optional, string, type } from "superstruct";

export const AdsConfigStruct = type({
  type: string(),
  position: string(),
  urls: array(string()),
  customParameters: optional(CustomAdsParametersStruct),
  adTrackingParameters: optional(AdTrackingParametersStruct),
  adTrackingEvents: optional(array(XPATrackingEventStruct)),
});

export type AdsConfig = Infer<typeof AdsConfigStruct>;
