import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { VideojsEmeOptions } from "@ui/blocks/VideoPlayer/types/VideojsEmeOptions";
import { VideojsPlaylistItem } from "@ui/blocks/VideoPlayer/types/VideoPlaylistItem";
import { isNullish } from "@/shared/helpers/isNullish";
import { getContentIdFunction } from "@ui/blocks/VideoPlayer/helpers/getContentIdFunction";
import { getLicenseFunction } from "@ui/blocks/VideoPlayer/helpers/getLicenseFunction";
import videojs from "video.js";

export function configPlaylistWithDRM(
  player: VideoJSPlayer,
  playlist: Array<VideojsPlaylistItem>,
): void {
  const shouldCreateSingleSource =
    videojs.browser.IS_SAFARI &&
    (videojs.browser.IS_IPHONE || videojs.browser.IS_IPAD);

  playlist.forEach((item) => {
    if (item.drm) {
      const drmToken = item.drm.token;
      if (player.eme !== undefined && !isNullish(drmToken)) {
        player.eme({
          emeHeaders: {
            customdata: drmToken,
          },
        });
      }

      item.sources.forEach((source, i) => {
        if (!isNullish(source)) {
          if (isNullish(item.drm?.fpCertificateUrl)) {
            const wvprDashSrc: VideojsEmeOptions = {
              src: source.src,
              type: source.type,
              keySystems: {
                "com.widevine.alpha":
                  item.drm?.widevineUrl ??
                  "https://widevine.keyos.com/api/v4/getLicense",
              },
            };
            item.sources[i] = wvprDashSrc;
          } else {
            const fairplayHlsSrc = {
              src: source.src,
              type: source.type,
              keySystems: {
                "com.apple.fps.1_0": {
                  certificateUri: item.drm.fpCertificateUrl,
                  licenseUri: item.drm.processSpcUrl,
                  getContentId: getContentIdFunction(item.drm),
                  getLicense: getLicenseFunction(item.drm),
                },
              },
            };
            item.sources[i] = fairplayHlsSrc;
          }
        }
      });
    }
  });

  if (player.playlist && !shouldCreateSingleSource) {
    player.playlist(playlist);
    player.playlist.autoadvance(0);
  } else {
    // Hack to play video on mobile clients without user interaction
    player.src(playlist[0].sources[0]);

    // Set manually ads since we don't have playlist item
    if (!isNullish(playlist[0]?.ads)) {
      player.customAdsConfig = playlist[0].ads;
    }

    player.on("adend", () => {
      player.src(playlist[0].sources[0]);
      player.autoplay(true);
    });
  }
}
