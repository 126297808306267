import { ClientParameterRequestStruct } from "@ui/blocks/VideoPlayer/types/ClientParameterRequest";
import { XPAEventStruct } from "@ui/blocks/VideoPlayer/types/XPAEventType";
import { array, Infer, optional, record, string, type } from "superstruct";
import { TrackerStruct } from "@ui/blocks/VideoPlayer/types/Tracker";
import { TypedServerParameterStruct } from "@/shared/types/TypedServerParameter";

export const XPATrackingEventStruct = type({
  type: optional(XPAEventStruct),
  name: string(),
  clientParameterRequests: optional(array(ClientParameterRequestStruct)),
  serverParameters: optional(record(string(), string())),
  typedServerParameter: optional(TypedServerParameterStruct),
  trackers: array(TrackerStruct),
  schema: optional(string()),
});

export type XPATrackingEvent = Infer<typeof XPATrackingEventStruct>;
