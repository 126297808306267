import { array, Infer, type } from "superstruct";
import { VideojsPlayerConfigStruct } from "@ui/blocks/VideoPlayer/types/VideoJSPlayerConfig";
import { VideojsPlaylistItemStruct } from "@ui/blocks/VideoPlayer/types/VideoPlaylistItem";

export const VideoPlayerDataStruct = type({
  config: VideojsPlayerConfigStruct,
  playlist: array(VideojsPlaylistItemStruct),
});

export type VideoPlayerData = Infer<typeof VideoPlayerDataStruct>;
