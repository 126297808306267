import React, { FC } from "react";
import { useLocale } from "@/shared/hooks/useLocale";
import { CardSectionTitle } from "@ui/blocks/CardSectionTitle";
import { VODSection } from "@features/discovery/blocks/VODSection";
import { DiscoveryGridItemWrapper } from "@features/discovery/elements/DiscoveryGridItemWrapper";
import { useGetTeamsRailQuery } from "@features/discovery/services/discoveryApi";
import { ColumnGrid } from "@features/discovery/elements/ColumnGrid";
import { VODSectionLoader } from "@features/discovery/blocks/VODSectionLoader";
import { isNullish } from "@/shared/helpers/isNullish";
import {
  GalleryNames,
  viewMoreTrackingEvent,
} from "@ui/blocks/CardSectionTitle/ViewMoreEvent";
import { SectionTypes } from "@/shared/types/SectionTypes";
import { trackingService } from "@features/tracking/services/trackingService";

export const TeamNewsSectionContainer: FC = () => {
  const { t, locale } = useLocale();
  const { data, isSuccess } = useGetTeamsRailQuery({
    locale,
  });

  if (!isSuccess) {
    return (
      <DiscoveryGridItemWrapper>
        <ColumnGrid minItemWidth="18.125rem">
          <VODSectionLoader
            numberOfItems={8}
            cardsTestID="team-news-cards-loader"
          />
        </ColumnGrid>
      </DiscoveryGridItemWrapper>
    );
  }

  if (isNullish(data)) {
    return null;
  }

  return data.map((rail) => {
    return (
      <DiscoveryGridItemWrapper key={rail.uiKey}>
        <CardSectionTitle
          linkTo="/clips"
          linkText={t("VIEW_MORE")}
          onClick={() =>
            void trackingService.sendEvent(
              viewMoreTrackingEvent(GalleryNames.Clips),
            )
          }
        >
          {rail.videoGallery.sectionHeader.title}
        </CardSectionTitle>
        <ColumnGrid minItemWidth="18.125rem">
          <VODSection
            numberOfSectionItems={8}
            items={rail.videoGallery.items}
            sectionType={SectionTypes.news}
          />
        </ColumnGrid>
      </DiscoveryGridItemWrapper>
    );
  });
};
