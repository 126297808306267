import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const Icon = styled.svg`
  width: 2.25rem;

  @media all and ${deviceSizeQuery.desktop} {
    width: 3.125rem;
  }
`;
