import Skeleton from "react-loading-skeleton";
import { CardContentTitle } from "@ui/elements/CardContentTitle";
import { CardWrapper } from "@ui/elements/CardWrapper";
import { CardContentWrapper } from "@ui/elements/CardContentWrapper";
import { FontSize } from "@themeTypes/FontSizeType";
import { MatchInfoWrapper } from "@ui/blocks/LiveMatchCard/elements/MatchInfoWrapper";
import { CompetitionWrapper } from "@ui/blocks/LiveMatchCard/elements/CompetitionWrapper";
import { PartnerSectionWrapper } from "@ui/blocks/LiveMatchCard/elements/PartnerSectionWrapper";
import { CardPartnerNameWrapper } from "@ui/elements/CardPartnerNameWrapper";
import { CardPartnerNameText } from "@ui/elements/CardPartnerNameText";
import { FC } from "react";

export const LiveMatchCardLoader: FC = () => {
  return (
    <CardWrapper as="div" data-testid="live-match-card-skeleton-loader">
      <Skeleton width="100%" style={{ aspectRatio: "16 / 9" }} />
      <CardContentWrapper>
        <CardContentTitle size={FontSize.BodyText_6} fontWeight={700}>
          <Skeleton width={218} />
        </CardContentTitle>
        <MatchInfoWrapper>
          <CompetitionWrapper>
            <Skeleton
              circle
              height={16}
              width={16}
              style={{ marginRight: "0.5rem" }}
            />
            <Skeleton width={100} />
          </CompetitionWrapper>

          <CardContentTitle>
            <Skeleton count={1} width="60%" style={{ marginTop: "0.5rem" }} />
          </CardContentTitle>
          <PartnerSectionWrapper>
            <CardPartnerNameWrapper>
              <Skeleton
                circle
                width={16}
                height={16}
                style={{ marginRight: "0.3rem" }}
              />
              <CardPartnerNameText size={FontSize.BodyText_8}>
                <Skeleton width={100} />
              </CardPartnerNameText>
            </CardPartnerNameWrapper>
            <CardContentTitle size={FontSize.BodyText_8}>
              <Skeleton width={150} />
            </CardContentTitle>
          </PartnerSectionWrapper>
        </MatchInfoWrapper>
      </CardContentWrapper>
    </CardWrapper>
  );
};
