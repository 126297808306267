import styled from "styled-components";

export const TitleWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  width: 100%;
`;
