import styled from "styled-components";
import { Slider } from "@ui/blocks/ToggleSwitch/elements/Slider";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { SliderIcon } from "@ui/blocks/ToggleSwitch/elements/SliderIcon";

export const HiddenInput = styled.input<WithThemeType>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${(p) => p.theme.colors.navigationBackground};
  }

  &:checked + ${Slider} ${SliderIcon} {
    transform: translateX(26px);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${(p) => p.theme.colors.navigationBackground};
  }
`;
