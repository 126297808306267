import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const MatchInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media all and ${deviceSizeQuery.tabletLandscape} {
    max-width: 70%;
  }
`;
