import { News } from "@features/rails/containers/News";
import { Route, Routes } from "react-router-dom";
import { PageLayoutContainer } from "@features/pageLayout/containers/PageLayoutContainer";
import { ErrorPage } from "@/pages/ErrorPage/ErrorPage";
import React, { FC, useEffect } from "react";
import { useInitTracking } from "@features/tracking/hooks/useInitTracking";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "@ui/elements/GlobalStyles";
import { ErrorBoundaryContainer } from "@features/errorBoundary/containers/ErrorBoundaryContainer";
import { useAppDispatch, useAppSelector } from "@/shared/hooks/appHooks";
import {
  selectIsDarkTheme,
  toggleDarkTheme,
} from "@features/pageLayout/store/pageLayoutSlice";
import { darkTheme, defaultTheme } from "@/shared/themes";
import { MatchDetailPage } from "@/pages/LiveMatchDetail/MatchDetailPage";
import { AppRoutes } from "@/shared/constants/Routes";
import { ClipsDetailPage } from "@/pages/ClipsDetail/ClipsDetailPage";
import { CreatorDetailPage } from "@/pages/CreatorDetail/CreatorDetailPage";
import { LiveMatchesPage } from "@/pages/LiveMatches/LiveMatchesPage";
import { DiscoveryPage } from "@/pages/Discovery/DiscoveryPage";
import { ClipsPage } from "@/pages/Clips/ClipsPage";
import { SkeletonTheme } from "react-loading-skeleton";
import { getSkeletonsConfig } from "@/utils/getSkeletonsConfig";
import { authenticate } from "@features/authentication";
import { checkAuthCookie } from "@features/authentication/checkAuthCookie";
import { useGetLocationQuery } from "@features/tracking/services/geolocationApi";

export const App: FC = () => {
  const dispatch = useAppDispatch();
  useInitTracking();
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  useGetLocationQuery();

  useEffect(() => {
    const isDark = localStorage.getItem("isDarkTheme");
    if (isDark !== null) {
      dispatch(toggleDarkTheme(JSON.parse(isDark) as boolean));
    }

    dispatch(authenticate(checkAuthCookie()));
  }, [dispatch]);

  const skeletonsConfig = getSkeletonsConfig(
    isDarkTheme ? darkTheme : defaultTheme,
  );

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : defaultTheme}>
      <SkeletonTheme
        baseColor={skeletonsConfig.baseColor}
        highlightColor={skeletonsConfig.highlightColor}
      >
        <GlobalStyles />
        <ErrorBoundaryContainer>
          <Routes>
            <Route element={<PageLayoutContainer />}>
              <Route path={AppRoutes.Home} element={<DiscoveryPage />} />
              <Route path={AppRoutes.Highlights} element={<ClipsPage />} />
              <Route path={AppRoutes.News} element={<News />} />
              <Route path={AppRoutes.Error} element={<ErrorPage />} />

              <Route path={AppRoutes.LiveMatches}>
                <Route index element={<LiveMatchesPage />} />
                <Route
                  path={AppRoutes.MatchDetail}
                  element={<MatchDetailPage />}
                />
              </Route>

              <Route path={AppRoutes.Clips}>
                <Route index element={<ClipsPage />} />
                <Route
                  path={AppRoutes.ClipsDetail}
                  element={<ClipsDetailPage />}
                />
              </Route>
              <Route
                path={`${AppRoutes.Creators}/${AppRoutes.CreatorDetail}`}
                element={<CreatorDetailPage />}
              />
            </Route>
          </Routes>
        </ErrorBoundaryContainer>
      </SkeletonTheme>
    </ThemeProvider>
  );
};
