import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/shared/store";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isAuthenticated: false,
  },
  reducers: {
    authenticate: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { authenticate } = authenticationSlice.actions;

export const selectIsAuthenticated = (state: RootState): boolean => {
  return state.authentication.isAuthenticated;
};

export const authenticationReducer = authenticationSlice.reducer;
