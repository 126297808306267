import { FC } from "react";
import { useLocale } from "@/shared/hooks/useLocale";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";

export const VerifiedIcon: FC = () => {
  const { t } = useLocale();

  return (
    <ImageWithFallBack
      src="https://images.onefootball.com/cw/icons/verified-icon-light.svg"
      darkModeSrc="https://images.onefootball.com/cw/icons/verified-icon-dark.svg"
      alt={t("VERIFY_ICON_ALT")}
      width={24}
      height={24}
    />
  );
};
