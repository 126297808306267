import styled from "styled-components";
import { ThemeColorType } from "@themeTypes/ThemeColorType";
import { FontSizeType } from "@themeTypes/FontSizeType";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { ellipsis } from "@ui/elements/GlobalStyles/helpers/ellipsis";
import { isNullish } from "@/shared/helpers/isNullish";

interface TextProps extends WithThemeType {
  color?: ThemeColorType;
  lineHeight?: string;
  fontWeight?: number;
  fontFamily?: string;
  size?: FontSizeType;
  isEllipsis?: boolean;
}

/**
 * Simple text container.
 */
export const BodyText = styled.p<TextProps>`
  font-family: ${(p) => p.fontFamily ?? "inherit"};
  font-size: ${(p) =>
    p.size
      ? p.theme.typography.mobileFontSize[p.size]
      : p.theme.typography.mobileFontSize.bodyText_7};
  font-weight: ${(p) => p.fontWeight ?? 400};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : "inherit")};
  line-height: ${(p) => p.lineHeight ?? 1.2};
  ${(p) => (!isNullish(p.isEllipsis) && p.isEllipsis ? ellipsis : "")}
  @media all and ${deviceSizeQuery.tabletPortrait} {
    font-size: ${(p) =>
      p.size
        ? p.theme.typography.desktopFontSize[p.size]
        : p.theme.typography.desktopFontSize.bodyText_7};
  }
`;
