import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/shared/store";

export interface LiveMatchesState {
  currentPage: number;
  filteredCompetition: string;
  price: string;
}

const initialState: LiveMatchesState = {
  currentPage: 0,
  filteredCompetition: "",
  price: "",
};

export const liveMatchesSlice = createSlice({
  name: "liveMatches",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setFilteredCompetition: (state, action: PayloadAction<string>) => {
      state.filteredCompetition = action.payload;
    },
    setFilteredPrice: (state, action: PayloadAction<string>) => {
      state.price = action.payload;
    },
  },
});

// Actions
export const { setCurrentPage, setFilteredCompetition, setFilteredPrice } =
  liveMatchesSlice.actions;

// Selectors
export const selectCurrentPage = (state: RootState): number =>
  state.liveMatches.currentPage;

export const selectFilteredCompetition = (state: RootState): string =>
  state.liveMatches.filteredCompetition;

export const selectFilteredPrice = (state: RootState): string =>
  state.liveMatches.price;

// Reducer
export const liveMatchesReducer = liveMatchesSlice.reducer;
