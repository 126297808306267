import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { TitleText } from "@ui/elements/TitleText";

export const PriceButton = styled(TitleText)<WithThemeType>`
  border: ${(p) => `1px solid ${p.theme.colors.border}`};
  padding: 0.1rem 0.5rem;
  margin-left: 0.2rem;
  display: flex;
  place-content: center;
  border-radius: ${(p) => p.theme.roundedCorners.regular};
`;
