import { create, func, type } from "superstruct";
import { configComscoreAnalytics } from "@ui/blocks/VideoPlayer/helpers/configComscoreAnalytics";
import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { ComscoreMetadata } from "@ui/blocks/VideoPlayer/types/ComscoreMetadata";

interface InitComscoreAnalyticsProps {
  player: VideoJSPlayer;
  comscoreMetadata: ComscoreMetadata;
}

export const initComscoreAnalytics = ({
  player,
  comscoreMetadata,
}: InitComscoreAnalyticsProps): void => {
  const StramingAnalyticsStruct = type({
    notifyPlay: func(),
    notifyPause: func(),
    notifyEnd: func(),
  });

  const streamingAnalytics = create(
    configComscoreAnalytics(comscoreMetadata),
    StramingAnalyticsStruct,
    "can not create",
  );

  player.on("play", () => {
    streamingAnalytics.notifyPlay();
  });

  player.on("pause", () => {
    streamingAnalytics.notifyPause();
  });

  player.on("ended", () => {
    streamingAnalytics.notifyEnd();
  });

  player.on("dispose", () => {
    streamingAnalytics.notifyEnd();
  });
};
