import { ChangeEvent, FC } from "react";
import { TextScreenReader } from "@ui/elements/TextScreenReader";
import { Select } from "@ui/blocks/DropDownMenu/elements/Select";
import { Option } from "@ui/blocks/DropDownMenu/elements/Option";
import { isNullish } from "@/shared/helpers/isNullish";

type Sections = Array<{
  title: string;
  onClick?: () => void;
  icon?: string;
  lang?: string;
}>;

export interface DropDownMenuProps {
  label: string;
  sections: Sections;
  currentLang?: string;
}

const getCurrentSection = (sections: Sections, currentLang: string) => {
  return sections.find((section) => section.lang === currentLang);
};

export const DropDownMenu: FC<DropDownMenuProps> = (props) => {
  const { sections, label, currentLang = "en" } = props;
  const currentSection = getCurrentSection(sections, currentLang);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedTitle = event.target.value;
    const selectedOption = sections.find(
      (option) => option.title === selectedTitle,
    );

    if (selectedOption && !isNullish(selectedOption.onClick)) {
      selectedOption.onClick();
    }
  };

  return (
    <>
      <TextScreenReader as={"label"}>{label}</TextScreenReader>
      <Select
        as={"select"}
        bgSrc={currentSection?.icon}
        onChange={handleChange}
      >
        {sections.map((option) => {
          return (
            <Option as={"option"} key={option.title} value={option.title}>
              {option.title}
            </Option>
          );
        })}
      </Select>
    </>
  );
};
