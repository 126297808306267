import { MutableRefObject, useEffect } from "react";

interface UseInfiniteScrollProps {
  ref: MutableRefObject<HTMLDivElement | null>;
  onEndReached: () => void;
}

export const INFINITE_SCROLL_BUFFER = 100;

export const useInfiniteScroll = ({
  ref,
  onEndReached,
}: UseInfiniteScrollProps): void => {
  useEffect(() => {
    const target = ref.current;
    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          onEndReached();
        }
      },
      {
        root: null, // observing intersections with the viewport
        rootMargin: "0px",
        threshold: 1.0, // fully in the viewport
      },
    );

    observer.observe(target);

    return () => observer.disconnect();
  }, [onEndReached, ref]);
};
