import { TeamTypeStruct } from "@/shared/types/TeamType";
import { Infer, optional, string, type } from "superstruct";
import { XPAiconObjectStruct } from "@/shared/types/XPAiconObjectType";

export const MatchScoreDataStruct = type({
  competition: type({
    name: string(),
    icon: XPAiconObjectStruct,
    link: optional(type({ name: optional(string()), urlPath: string() })),
  }),
  kickoff: type({
    date: optional(string()),
    time: string(),
    utcTimestamp: string(),
  }),
  link: optional(string()),
  timePeriod: optional(string()),
  period: string(),
  homeTeam: TeamTypeStruct,
  awayTeam: TeamTypeStruct,
});

export type MatchScoreData = Infer<typeof MatchScoreDataStruct>;
