import styled, { css } from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { ZIndexes } from "@/shared/constants/zIndexes";

interface WidgetsSidebarWrapperType extends WithThemeType {
  isSidebarVisible: boolean;
  shouldBeDrawer: boolean;
}

export const WidgetsSidebarWrapper = styled.aside<WidgetsSidebarWrapperType>`
  overflow: scroll;
  ${(p) =>
    p.shouldBeDrawer &&
    css`
      width: 85%;
      position: absolute;
      background-color: ${p.theme.colors.navigationBackground};
      border-left: 1px solid ${p.theme.colors.border};
      padding: 1.2rem;
    `};
  top: 0;
  right: ${(p) => (p.isSidebarVisible ? 0 : "-85%")};
  z-index: ${ZIndexes.FiltersWidget}; // needs to go on top of video playing ads
  height: 100%;
  transition: all 0.3s ease;
  @media all and ${deviceSizeQuery.tabletLandscape} {
    position: static;
    background-color: transparent;
    width: 100%;
    border-left: none;
    padding: 0;
  }
`;
