const Base = 0;
const Above = 1;
const Below = -1;
const Max = 9999; // must go on top of ads

const FiltersWidget = Max + Below;

export const ZIndexes = {
  Base,
  Above,
  Below,
  Max,
  FiltersWidget,
};
