import { EventName } from "@/modules/tracking/constants/EventName";
import { Event } from "@features/tracking/services/trackingService";

export const GalleryNames = {
  Clips: "Clips",
  Highlights: "Highlights",
  LiveMatches: "LiveMatches",
  VideoNews: "VideoNews",
} as const;

export type GalleryNameType = keyof typeof GalleryNames;
export const viewMoreTrackingEvent = (galleryName: GalleryNameType): Event => ({
  name: EventName.ViewMoreClicked,
  properties: {
    gallery_name: galleryName,
  },
});
