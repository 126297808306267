import styled from "styled-components";
import { deviceSizeQuery } from "@/shared/constants/deviceSizeQuery";

export const Wrapper = styled.div`
  position: relative;
  min-height: 70vh;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  overflow: hidden;
  @media all and ${deviceSizeQuery.tabletPortrait} {
    min-height: 60vh;
  }
`;
